import React from "react"
import { Table } from "react-bootstrap"
import Loading from "../Auxiliaries/Loading"
import moment from 'moment'
import 'moment/locale/pt-br'
import { FaWhatsapp } from "react-icons/fa";

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { SparkLineChart } from '@mui/x-charts/SparkLineChart'


class Clients extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            clientsWTracking: [],
            pageReady: false,
        }

        this.clientsPhone = []
        this.tryPhone2 = []

        this.dateToSetActive = moment.utc(new Date()).format('YYYY-MM-01T00:00:00')

        this.clientsLoaded = false

    }

    async componentDidMount() {
        this.getClients()

        // this.validateWappNumber()
    }

    componentWillUnmount() {
        this.clientsLoaded = false
    }

    validateWappNumber = async () => {
        while (!this.clientsLoaded) {
            await this.sleep(2000)
        }
        this.wappNumberMap(100)
    }

    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")
        var clientLength = 999999999

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {

                const clientsWTracking = []

                data.map(client => {
                    const tracking = client.tracking
                        .toSorted(function (a, b) {
                            var c = new Date(a.refDate);
                            var d = new Date(b.refDate);
                            return c - d;
                        })

                    const trackingInsideForMonth = []
                    const trackingInsideForVisits = []
                    const trackingInsideForTicket = []

                    for (let index = 1; index <= 12; index++) {

                        const monthVisit = moment.utc(new Date(this.dateToSetActive)).subtract(index, "month")
                        const hasVisitInMonth = tracking
                            .filter((track) => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthVisit.format('MMMM/YYYY') && track.ticket !== 0)

                        if (hasVisitInMonth.length !== 0) {
                            trackingInsideForMonth.push(monthVisit.format('MMMM/YYYY'))
                            trackingInsideForVisits.push(hasVisitInMonth[0].visits)
                            trackingInsideForTicket.push(hasVisitInMonth[0].ticket)
                        } else {
                            trackingInsideForMonth.push(monthVisit.format('MMMM/YYYY'))
                            trackingInsideForVisits.push(0)
                            trackingInsideForTicket.push(0)
                        }
                    }

                    clientsWTracking.push({
                        _id: client._id,
                        name: client.name,
                        phone1: client.phone1,
                        hasWhatsapp: client.hasWhatsapp,
                        first_date: client.first_date,
                        last_date: client.last_date,
                        birthday: client.birthday,
                        last_ticket: client.last_ticket,
                        last_last_ticket: client.last_last_ticket,
                        active: client.active,
                        tracking: {
                            months: trackingInsideForMonth.reverse(),
                            visits: trackingInsideForVisits.reverse(),
                            ticket: trackingInsideForTicket.reverse()
                        }
                    })
                    return 0
                })

                this.setState({
                    clients: data.toSorted((a, b) => a.last_ticket < b.last_ticket ? 1 : -1),
                    clientsWTracking: clientsWTracking.toSorted((a, b) => a.last_ticket < b.last_ticket ? 1 : -1)
                })
                clientLength = data.length

                this.clientsPhone = data.filter(client => typeof client.hasWhatsapp === 'undefined')
                // this.clientsPhone = data.filter(client => client.phone1[0] !== '5' && client.phone1[1] !== '5')
                // this.clientsPhone = data.filter(client => !client.hasWhatsapp)


                console.log('clientes nao verificados: ' + this.clientsPhone.length)

            })
            .catch(rejected => {
                console.log(rejected);
            })
            .finally(data => {
                if (clientLength !== 999999999) {
                    this.setState({ pageReady: true })
                    this.clientsLoaded = true
                }
            })

    }

    sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    updateClient = async (data) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    // console.log('UPDATE COMPLETED!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    wappNumberMap = async (limit) => {

        if (this.clientsPhone.length > 0) {

            console.log("Identifying WhatsApp numbers")
            console.log("Total " + this.clientsPhone.length)
            console.log("Limit " + limit)
            console.log("START: " + moment(new Date()).format('DD/MM/YYYY - HH:mm:ss'))

            let repeat = 1

            for (let index = 0; index < this.clientsPhone.length; index++) {

                console.log((index + 1) + ' de ' + this.clientsPhone.length + ' - PHONE 1')

                const element = this.clientsPhone[index]
                const resp = await this.checkWappNumber(element, 'PHONE 1')
                console.log(resp)

                if (index % 2 === 0) {
                    console.log('waiting 2s\n')
                    console.log('--')
                    await this.sleep(2000)
                } else {
                    console.log('waiting 4s\n')
                    console.log('--')
                    await this.sleep(4000)
                }

                if (repeat >= limit) {
                    repeat = 1
                    console.log('--')
                    console.log('waiting 5min\n')
                    console.log('--')
                    await this.sleep(300000)
                } else {
                    repeat = repeat + 1
                }
            }

            console.log("END: " + moment(new Date()).format('DD/MM/YYYY - HH:mm:ss'))

        } else {
            console.log('Todos os clientes já verificados')
        }

    }

    removeNinethDigit = (phone) => {

        const ddd = (phone.replace(/[-+ ()_]/g, '')).slice(2, 4)

        if (Number(ddd) >= 11 && Number(ddd) <= 28) {  //sp 11-19, rj 21,22,24, es 27-28
            return false
        }
        return true
    }

    checkWappNumber = async (client, trying) => {


        let phone = trying !== 'PHONE 2'
            ? (this.phoneNumberCorrection(client.phone1)).replace(/[-+ ()_]/g, '')
            : (this.phoneNumberCorrection(client.phone2)).replace(/[-+ ()_]/g, '')

        if (this.removeNinethDigit(phone)) {
            console.log('DDD para RETIRAR 9')
            if (phone.length === 13) {
                phone = phone.slice(0, 4) + phone.slice(5, 13)
            }
        }

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        let respCheckedNum = 'not checked'

        console.log(phone)
        if (phone !== '' && phone.length <= 13) {

            await fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/action/is-registered-user", {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    authorization: 'Bearer ' + token
                },
                body: JSON.stringify({ contactId: phone + '@c.us' })
            })
                .then(res => res.json())
                .then(async data => {
                    respCheckedNum = data.data.status
                    if (typeof data.data.data !== 'undefined') {
                        if (!data.data.data.isRegisteredUser && trying !== 'PHONE 2') { //nao tem wapp phone1 inicial
                            if (client.phone2 !== '') { //tentar phone2
                                await this.updateClient([{
                                    _id: client._id,
                                    phone1: phone
                                }])
                                console.log('trying phone 2')
                                console.log('waiting 2s\n')
                                console.log('--')
                                await this.sleep(2000)
                                await this.checkWappNumber(client, 'PHONE 2')
                            } else { //nao tem wapp phone1 e nao possui phone2 p tentar
                                await this.updateClient([{
                                    _id: client._id,
                                    phone1: phone,
                                    hasWhatsapp: false
                                }])
                            }
                        } else if (!data.data.data.isRegisteredUser && trying === 'PHONE 2') { //nao tem wapp phone2
                            await this.updateClient([{
                                _id: client._id,
                                phone2: phone,
                                hasWhatsapp: false
                            }])
                        } else { //tem wapp
                            if (trying === 'PHONE 2') {  //tem wapp phone2 - trocar p phone1
                                await this.updateClient([{
                                    _id: client._id,
                                    phone1: phone,
                                    phone2: client.phone1,
                                    hasWhatsapp: true
                                }])
                            } else { //tem wapp phone 1 inicial
                                await this.updateClient([{
                                    _id: client._id,
                                    phone1: phone,
                                    hasWhatsapp: true
                                }])
                            }
                        }
                    } else if (trying !== 'PHONE 2') {//tentar phone2
                        await this.updateClient([{
                            _id: client._id,
                            phone1: phone
                        }])
                        console.log('trying phone 2')
                        console.log('waiting 2s\n')
                        console.log('--')
                        await this.sleep(2000)
                        await this.checkWappNumber(client, 'PHONE 2')
                    } else if (trying === 'PHONE 2') {//nao tem wapp phone1 e phone2
                        await this.updateClient([{
                            _id: client._id,
                            phone2: phone,
                            hasWhatsapp: false
                        }])
                    }
                })
                .catch(err => {
                    console.error('error:' + err)
                })

        } else if (trying !== 'PHONE 2') {//tentar phone2
            console.log('trying phone 2')
            console.log('waiting 2s\n')
            console.log('--')
            await this.sleep(2000)
            await this.checkWappNumber(client, 'PHONE 2')
        } else if (trying === 'PHONE 2') {//não tem wapp phone1 e phone2
            await this.updateClient([{
                _id: client._id,
                phone2: phone,
                hasWhatsapp: false
            }])
        }

        return respCheckedNum


    }

    uppercaseEachWords = (sentence) => {

        if (typeof sentence !== 'undefined') {
            const words = sentence.split(' ')

            return words.map((word) => {
                if (word.length > 1) {
                    return word[0] + word.substring(1).toLowerCase()
                } else {
                    return word[0]
                }
            }).join(" ")
        }

        return sentence
    }

    phoneNumberCorrection = (phone) => {

        const onlyNum = phone.replace(/[-+ ()_]/g, '')

        if (typeof onlyNum !== 'undefined' && onlyNum !== '') {

            if (onlyNum.length > 13) {
                if (onlyNum[onlyNum.length - 9])
                    return '+55' + onlyNum.slice(onlyNum.length - 11, onlyNum.length - 9)
                        + ' ' + onlyNum.slice(onlyNum.length - 9, onlyNum.length - 4)
                        + ' ' + onlyNum.slice(onlyNum.length - 4, onlyNum.length)
            }

            //already ok - BRAZIL
            if (onlyNum[0] === '5' && onlyNum[1] === '5') {
                return '+' + onlyNum.slice(0, 2)
                    + ' ' + onlyNum.slice(2, 4)
                    + ' ' + onlyNum.slice(4, onlyNum.length - 4)
                    + ' ' + onlyNum.slice(onlyNum.length - 4, onlyNum.length)
            }
            //check DDI
            if (onlyNum[0] === '+') {
                return onlyNum
            }

            //remove first zero DDD
            if (onlyNum[0] === '0') {
                console.log(onlyNum.slice(1))
                if (onlyNum.slice(1).length >= 10) { //correct number already
                    if (onlyNum.slice(1).length === 10) {
                        return '+55 '
                            + (onlyNum.slice(1)).slice(0, 2)
                            + ' '
                            + ((onlyNum.slice(1)).slice(2)).slice(((onlyNum.slice(1)).slice(2)).length - 8, ((onlyNum.slice(1)).slice(2)).length - 4)
                            + ' '
                            + ((onlyNum.slice(1)).slice(2)).slice(((onlyNum.slice(1)).slice(2)).length - 4, ((onlyNum.slice(1)).slice(2)).length)
                    } else if (onlyNum.slice(1).length > 10) {
                        return '+55 '
                            + (onlyNum.slice(1)).slice(0, 2)
                            + ' '
                            + ((onlyNum.slice(1)).slice(2)).slice(((onlyNum.slice(1)).slice(2)).length - 9, ((onlyNum.slice(1)).slice(2)).length - 4)
                            + ' '
                            + ((onlyNum.slice(1)).slice(2)).slice(((onlyNum.slice(1)).slice(2)).length - 4, ((onlyNum.slice(1)).slice(2)).length)
                    }

                } else { //incorrect number
                    return ''
                }
            } else {
                if (onlyNum.length >= 10) { //correct number already
                    if (onlyNum.length === 10) {
                        return '+55 '
                            + onlyNum.slice(0, 2)
                            + ' '
                            + (onlyNum.slice(2)).slice(0, (onlyNum.slice(2)).length - 4)
                            + ' '
                            + (onlyNum.slice(2)).slice((onlyNum.slice(2)).length - 4, (onlyNum.slice(2)).length)
                    } else if (onlyNum.length > 10) {
                        return '+55 '
                            + onlyNum.slice(0, 2)
                            + ' '
                            + (onlyNum.slice(2)).slice((onlyNum.slice(2)).length - 9, (onlyNum.slice(2)).length - 4)
                            + ' '
                            + (onlyNum.slice(2)).slice((onlyNum.slice(2)).length - 4, (onlyNum.slice(2)).length)
                    }

                } else { // without DDD
                    // console.log('INCORRETO: ' + onlyNum)
                    return '+55 ' + localStorage.getItem("ddd") + ' '
                        + onlyNum.slice(0, onlyNum.length - 4)
                        + ' '
                        + onlyNum.slice(onlyNum.length - 4, onlyNum.length)
                }
            }
        }
        return ''
    }

    phoneNumberCorrectionV2 = (client) => {

        const phone = client.phone1

        if (typeof client.hasWhatsapp !== 'undefined') {
            return '+' + phone.slice(0, 2) +
                ' ' + phone.slice(2, 4) +
                ' ' + phone.slice(4, phone.length - 4) +
                ' ' + phone.slice(phone.length - 4, phone.length)
        }
        return 'Não formatado'
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.pageReady
                        ?
                        <>
                            <h3>Lista de clientes</h3> ({this.state.clients.length} clientes na base)

                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Telefone</th>
                                        <th>WhatsApp</th>
                                        <th>Primeira visita</th>
                                        <th>Última visita</th>
                                        <th>Aniversário</th>
                                        <th>Gasto mês passado</th>
                                        {/* <th>Ticket mês retrasado</th> */}
                                        {/* <th>Situação (90dias)</th> */}
                                        <th>Visitas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.clientsWTracking
                                            .map((client) =>
                                                <tr key={client._id}>
                                                    <td>{this.uppercaseEachWords(client.name)}</td>
                                                    <td>{(this.phoneNumberCorrectionV2(client))}</td>
                                                    {client.hasWhatsapp
                                                        ? <td style={{ color: '#24d366' }}><FaWhatsapp /></td>
                                                        : typeof client.hasWhatsapp !== 'undefined'
                                                            ? <td style={{ color: '#ed4545' }}>Nao tem</td>
                                                            : <td style={{ color: '#ed4545' }}>Nao verificado</td>
                                                    }
                                                    <td>{moment.utc(new Date(client.first_date)).format('DD/MM/YYYY')}</td>
                                                    <td>{moment.utc(new Date(client.last_date)).format('DD/MM/YYYY')}</td>
                                                    <td>{client.birthday ? moment.utc(new Date(client.birthday)).format('MMMM') : "-"}</td>
                                                    <td>R$ {(typeof client.last_ticket !== 'undefined') ? (client.last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td>
                                                    {/* <td>R$ {(typeof client.last_last_ticket !== 'undefined') ? (client.last_last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td> */}
                                                    {/* <td style={client.active ? { color: '#24d366' } : { color: '#ed4545' }}>{client.active ? 'Ativo' : 'Inativo'}</td> */}
                                                    <td>
                                                        <Stack direction="row" >
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <SparkLineChart
                                                                    plotType="bar"
                                                                    data={client.tracking.visits}
                                                                    height={30}
                                                                    width={100}
                                                                    colors={['#24d366']}
                                                                    showTooltip
                                                                    showHighlight
                                                                    xAxis={{
                                                                        scaleType: 'band',
                                                                        data: client.tracking.months
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </td>
                                                </tr>

                                            )
                                    }
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Clients;