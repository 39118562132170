import { React, useRef } from 'react'
import * as XLSX from 'xlsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Button } from "react-bootstrap"

function FileInputBestClients() {

    const hiddenFileInput = useRef(null)

    const handleClick = event => {
        hiddenFileInput.current.click()
    }

    const handleChange = event => {
        handleFileUpload(event)
    }

    const userId = localStorage.getItem("resd")
    const campId = localStorage.getItem("camp")
    const user_pdv = localStorage.getItem("pdv")

    const daysToBeActive = 90

    const bestClientsRequest = []

    const importDateDiaries = []
    const monthExpectedToImportLong = []

    const biDiaries = []
    const diaries = []
    const clientsAlive = []

    let clientsGotten = false
    let total_biwapp = 0


    getClients()

    getImportDateDiaries()

    getBiDiaries()

    getDiaries()

    const handleFileUpload = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = async (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = XLSX.utils.sheet_to_json(sheet)

            const clientsFromSheet = await integrationSelector(sheetData)

            if (clientsFromSheet.length > 0) {

                while (!clientsGotten) {
                    // console.log("wait clients request")
                    await sleep(1000)
                }

                const clientsRequestSortedByDate = sortByDate(clientsFromSheet)
                await createImportDateDiary(clientsRequestSortedByDate)

                const clientsToUpdate = getClientsToUpdate(bestClientsRequest, clientsAlive)
                if (clientsToUpdate.length > 0) {
                    await updateClient(clientsToUpdate)
                }
                const clientsToUpdateZeroTicket = getClientsToUpdateZeroTicket(clientsAlive, clientsToUpdate)
                if (clientsToUpdateZeroTicket.length > 0) {
                    await updateClient(clientsToUpdateZeroTicket)
                }
                const clientsToCreate = getClientsToCreate(bestClientsRequest, clientsAlive)
                if (clientsToCreate.length > 0) {
                    await createClient(clientsToCreate)
                }

                await updateDiaryConvertion(clientsToUpdate)

                const totalClientsOnTheBIWApp = [...clientsToUpdate, ...clientsToUpdateZeroTicket, ...clientsToCreate]
                await createBiDiary(clientsRequestSortedByDate, clientsToUpdate, totalClientsOnTheBIWApp)

                await updateCampaigns({ status_camp2: 0, status_camp3: 0 }, campId)

                alert("Arquivo importado com sucesso!")
                window.location.reload()

            }

        }

        reader.readAsBinaryString(file);

    }

    async function integrationSelector(sheetData) {

        if (user_pdv === "allegro") {
            return await integration5asec(sheetData)
        }
        if (user_pdv === "elav") {
            return await integrationElav(sheetData)
        }
        if (user_pdv === "swl") {
            return await integrationSwl(sheetData)
        }
        if (user_pdv === "presence") {
            return await integrationDrycleanusa(sheetData)
        }

    }

    async function integration5asec(sheetData) {

        if (sheetData[0].__EMPTY_1 === 'Melhores Clientes') {

            const fileMonth = (sheetData[0].__EMPTY_2).split(' ')
            const fileEndDate = fileMonth[fileMonth.length - 1]
            const fileMonthAndYear = fileEndDate.slice(3)
            const monthExpectedToImport = getMonthExpectedToImport()

            if (fileMonthAndYear === monthExpectedToImport) {

                const clients = await setClientsBest_5asec(sheetData)

                const clearedClients = clients.filter(client => client.last_date !== '' && client.last_date !== 'Invalid Date')

                return clearedClients

            } else {
                alert('\nImportação do período incorreto!'
                    + '\n\nVocê está importando o mês de ' + fileMonthAndYear
                    + '\n\nPrecisamos do mês de ' + monthExpectedToImport)
            }
        } else {
            alert("\nO arquivo escolhido não é a lista de MELHORES CLIENTES compatível")
        }

        return []

    }

    async function integrationElav(sheetData) {

        // console.log(sheetData)

        const planFieldNames = Object.getOwnPropertyNames(sheetData[0])

        let isVendas = false
        let isExpectedMonth = false
        let fileMonthAndYear = ''
        let visitDateFieldName = ''
        const monthExpectedToImport = getMonthExpectedToImport()

        for (let index = 0; index < planFieldNames.length; index++) {
            const element = planFieldNames[index]
            if (element.includes("VENDAS")) {
                isVendas = true
                visitDateFieldName = element
                const fieldItems = planFieldNames[index].split(' ')
                const fileEndDate = fieldItems[fieldItems.length - 1]
                fileMonthAndYear = fileEndDate.slice(3)

                if (fileMonthAndYear === monthExpectedToImport) {
                    isExpectedMonth = true
                }
            }
        }

        if (isVendas) {

            if (isExpectedMonth) {

                const clients = await setClientsBest_elav_preprocess(sheetData, visitDateFieldName)

                const clearedClients = clients.filter(client => client.name !== '' && client.name !== 'Cliente')

                const unique = [...new Set(clearedClients.map(item => item.name))]

                const array_clients = []

                unique.map(client => {
                    let cli = {
                        name: client,
                        last_date: [],
                        tickets: []
                    }
                    for (let index = 0; index < clearedClients.length; index++) {
                        if (clearedClients[index].name === client) {
                            const dates = (clearedClients[index].last_date).split('/')
                            cli.last_date.push(dates[2] + "-" + dates[1] + "-" + dates[0] + "T03:00:00")
                            cli.tickets.push(Number((clearedClients[index].last_ticket.replace(/ /g, '')).replace(',', '.')))
                        }
                    }
                    (cli.last_date).sort(function (a, b) {
                        var c = new Date(a)
                        var d = new Date(b)
                        return d - c
                    })
                    array_clients.push(cli)
                    return 0
                })

                const final_clients = await setClientsBest_elav(array_clients)

                return final_clients

            } else {
                alert('\nImportação do período incorreto!'
                    + '\n\nVocê está importando o mês de ' + fileMonthAndYear
                    + '\n\nPrecisamos do mês de ' + monthExpectedToImport)
            }

        } else {
            alert("\nO arquivo escolhido não é o RELATÓRIO DE VENDAS compatível")
        }

        return []

    }

    async function integrationSwl(sheetData) {

        console.log(sheetData)

        const planFieldNames = Object.getOwnPropertyNames(sheetData[0])

        let isAcceptable = false
        let isExpectedMonth = false
        let fileMonthAndYear = ''
        let visitDateFieldName = ''
        const monthExpectedToImport = getMonthExpectedToImport()

        if (
            planFieldNames.includes("Cod.") && planFieldNames.includes("Cliente") &&
            planFieldNames.includes("Data Atendimento") && planFieldNames.includes("Qtde Itens") &&
            planFieldNames.includes("Valor")
        ) {
            isAcceptable = true
        }

        for (let index = 0; index < planFieldNames.length; index++) {
            const element = planFieldNames[index]
            if (element.includes("Data Atendimento")) {
                visitDateFieldName = element
                const firstDates = []
                for (let index = 0; index < sheetData.length; index++) {
                    const element = sheetData[index][visitDateFieldName]
                    const firstDate = (typeof element != 'undefined' && new Date(Date.UTC(0, 0, element - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                        ? new Date(Date.UTC(0, 0, element - 1)).toUTCString()
                        : typeof element != 'undefined'
                            ? new Date(Date.UTC(0, 0, element)).toUTCString()
                            : '')
                    firstDates.push(firstDate)
                }

                fileMonthAndYear = moment(firstDates.sort(function (a, b) {
                    var c = new Date(a);
                    var d = new Date(b);
                    return d - c;
                })[0]).format('MM/YYYY')

                if (fileMonthAndYear === monthExpectedToImport) {
                    isExpectedMonth = true
                }
            }
        }

        if (isAcceptable) {

            if (isExpectedMonth) {

                const clearedClients = await setClientsBest_swl_preprocess(sheetData)

                console.log(clearedClients)

                const unique = [...new Set(clearedClients.map(item => item.name))]

                const array_clients = []

                unique.map(client => {
                    let cli = {
                        name: client,
                        last_date: [],
                        tickets: [],
                        items: []
                    }
                    for (let index = 0; index < clearedClients.length; index++) {
                        if (clearedClients[index].name === client) {
                            const dates = moment(clearedClients[index].last_date).format("YYYY-MM-DDT03:00:00")
                            cli.last_date.push(dates)
                            cli.tickets.push(Number((String(clearedClients[index].last_ticket).replace(/ /g, '')).replace(',', '.')))
                            cli.items.push(Number((String(clearedClients[index].items).replace(/ /g, ''))))
                        }
                    }
                    (cli.last_date).sort(function (a, b) {
                        var c = new Date(a)
                        var d = new Date(b)
                        return d - c
                    })
                    array_clients.push(cli)
                    return 0
                })

                console.log(array_clients)

                const final_clients = await setClientsBest_swl(array_clients)

                console.log(final_clients)

                return final_clients

            } else {
                alert('\nImportação do período incorreto!'
                    + '\n\nVocê está importando o mês de ' + fileMonthAndYear
                    + '\n\nPrecisamos do mês de ' + monthExpectedToImport)
            }

        } else {
            alert("\nO arquivo escolhido não é o RELATÓRIO DE ATENDIMENTOS compatível")
        }

        return []

    }

    async function integrationDrycleanusa(sheetData) {

        const planFieldNames = Object.getOwnPropertyNames(sheetData[0])

        let isAcceptable = false
        let isExpectedMonth = false
        let fileMonthAndYear = ''
        let visitDateFieldName = ''
        const monthExpectedToImport = getMonthExpectedToImport()

        if (
            planFieldNames.includes("Nome") && planFieldNames.includes("Freq") &&
            planFieldNames.includes("Total Vendas") && planFieldNames.includes("clcCelular") &&
            planFieldNames.includes("Telefone") && planFieldNames.includes("Mês") &&
            planFieldNames.includes("Cod.Cliente") && planFieldNames.includes("Primeira Compra") &&
            planFieldNames.includes("Última Compra")
        ) {
            isAcceptable = true
        }

        for (let index = 0; index < planFieldNames.length; index++) {
            const element = planFieldNames[index]
            if (element.includes("Primeira Compra")) {
                visitDateFieldName = element
                const firstDates = []
                for (let index = 0; index < sheetData.length; index++) {
                    const element = sheetData[index][visitDateFieldName]
                    const firstDate = (typeof element != 'undefined' && new Date(Date.UTC(0, 0, element - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                        ? new Date(Date.UTC(0, 0, element - 1)).toUTCString()
                        : typeof element != 'undefined'
                            ? new Date(Date.UTC(0, 0, element)).toUTCString()
                            : '')
                    firstDates.push(firstDate)
                }

                fileMonthAndYear = moment(firstDates.sort(function (a, b) {
                    var c = new Date(a);
                    var d = new Date(b);
                    return d - c;
                })[0]).format('MM/YYYY')

                if (fileMonthAndYear === monthExpectedToImport) {
                    isExpectedMonth = true
                }
            }
        }


        if (isAcceptable) {

            if (isExpectedMonth) {

                const clients = await setClientsBest_Drycleanusa(sheetData, planFieldNames)

                return clients

            } else {
                alert('\nImportação do período incorreto!'
                    + '\n\nVocê está importando o mês/ano de ' + fileMonthAndYear
                    + '\n\nPrecisamos do mês/ano de ' + monthExpectedToImport)
            }

        } else {
            alert("\nO arquivo escolhido não é o RELATÓRIO MENSAL ÚNICO compatível")
        }

        return []

    }

    async function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    function getMonthExpectedToImport() {

        var monthExpected = 'Not Found'

        importDateDiaries.map((importDiary) => {
            if (importDiary.bestClients.total === 0 && monthExpected === 'Not Found') {
                monthExpected = moment.utc(new Date(importDiary.date)).format('MM/YYYY')
                monthExpectedToImportLong.push(
                    {
                        month: moment.utc(new Date(importDiary.date)).format('MMMM/YYYY'),
                        date: moment.utc(new Date(importDiary.date)).format('YYYY-MM-01T12:00:00')
                    }
                )
            }
            return 0
        })

        return monthExpected

    }

    async function setClientsBest_5asec(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: (typeof client.__EMPTY_5 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)).toUTCString()
                    : typeof client.__EMPTY_5 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_5)).toUTCString()
                        : ''),
                name: (typeof client.__EMPTY_1 != 'undefined' ? client.__EMPTY_1 : ''),
                phone1: (typeof client.__EMPTY_2 != 'undefined' ? client.__EMPTY_2 : ''),
                phone2: (typeof client.__EMPTY_3 != 'undefined' ? client.__EMPTY_3 : ''),
                first_date: (typeof client.__EMPTY_5 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)).toUTCString()
                    : typeof client.__EMPTY_5 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_5)).toUTCString()
                        : ''),
                tracking: {
                    last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                        : typeof client.__EMPTY_6 != 'undefined'
                            ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                            : ''),
                    ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                    visits: (typeof client.__EMPTY_8 != 'undefined' ? client.__EMPTY_8 : ''),
                    items: (typeof client.__EMPTY_9 != 'undefined' ? client.__EMPTY_9 : ''),
                    refDate: monthExpectedToImportLong[0].date
                },
                last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                last_last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                last_ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                last_last_ticket: 0,
                last_active_ticket: (typeof client.__EMPTY_7 != 'undefined' ? client.__EMPTY_7 : ''),
                camp_history: {
                    refDate: monthExpectedToImportLong[0].date,
                    camp1: false,
                    camp1_date: "",
                    camp2: false,
                    camp2_date: "",
                    camp3: false,
                    camp3_date: "",
                    camp4: false,
                    camp4_date: "",
                    camp5: false,
                    camp5_date: ""
                },
                camp1: '',
                camp2: '',
                camp3: '',
                camp4: '',
                camp5: '',
                active: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? (moment.utc(new Date(monthExpectedToImportLong[0].date)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString())), 'days') < daysToBeActive ? true : false)
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? (moment.utc(new Date(monthExpectedToImportLong[0].date)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString())), 'days') < daysToBeActive ? true : false)
                        : false)
            })
        )
        return clients

    }

    async function setClientsBest_elav_preprocess(data, visitDateFieldName) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                name: (typeof client.__EMPTY_6 != 'undefined' ? client.__EMPTY_6 : ''),
                last_date: client[visitDateFieldName],
                last_ticket: (typeof client.__EMPTY_30 != 'undefined' ? client.__EMPTY_30 : '')
            })
        )
        return clients

    }

    async function setClientsBest_swl_preprocess(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: (typeof client["Cliente"] != 'undefined' ? client["Cliente"] : ''),
                name: (typeof client["Cliente"] != 'undefined' ? client["Cliente"] : ''),
                last_date: (typeof client["Data Atendimento"] !== 'undefined' && new Date(Date.UTC(0, 0, client["Data Atendimento"] - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client["Data Atendimento"] - 1)).toUTCString()
                    : typeof client["Data Atendimento"] !== 'undefined'
                        ? new Date(Date.UTC(0, 0, client["Data Atendimento"])).toUTCString()
                        : ''),
                last_ticket: (typeof client["Valor"] != 'undefined' ? client["Valor"] : ''),
                items: (typeof client["Qtde Itens"] != 'undefined' ? client["Qtde Itens"] : '')
            })
        )
        return clients

    }

    async function setClientsBest_elav(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: (client.name).split('-')[0],
                name: (client.name).split('-')[1],
                phone1: '',
                phone2: '',
                first_date: '',
                tracking: {
                    last_date: client.last_date[0],
                    ticket: (client.tickets).reduce((a, b) => a + b, 0),
                    visits: ([...new Set(client.last_date.map(item => item))]).length,
                    items: 0,
                    refDate: monthExpectedToImportLong[0].date
                },
                last_date: client.last_date[0],
                last_last_date: client.last_date[0],
                ticket: (client.tickets).reduce((a, b) => a + b, 0),
                last_ticket: (client.tickets).reduce((a, b) => a + b, 0),
                last_last_ticket: 0,
                last_active_ticket: (client.tickets).reduce((a, b) => a + b, 0),
                camp_history: {
                    refDate: monthExpectedToImportLong[0].date,
                    camp1: false,
                    camp1_date: "",
                    camp2: false,
                    camp2_date: "",
                    camp3: false,
                    camp3_date: "",
                    camp4: false,
                    camp4_date: "",
                    camp5: false,
                    camp5_date: ""
                },
                camp1: '',
                camp2: '',
                camp3: '',
                camp4: '',
                camp5: '',
                active: true
            })
        )
        return clients

    }

    async function setClientsBest_swl(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: client.unique_identifier,
                name: client.name,
                phone1: '',
                phone2: '',
                first_date: '',
                tracking: {
                    last_date: client.last_date[0],
                    ticket: (client.tickets).reduce((a, b) => a + b, 0),
                    visits: ([...new Set(client.last_date.map(item => item))]).length,
                    items: (client.items).reduce((a, b) => a + b, 0),
                    refDate: monthExpectedToImportLong[0].date
                },
                last_date: client.last_date[0],
                last_last_date: client.last_date[0],
                ticket: (client.tickets).reduce((a, b) => a + b, 0),
                last_ticket: (client.tickets).reduce((a, b) => a + b, 0),
                last_last_ticket: 0,
                last_active_ticket: (client.tickets).reduce((a, b) => a + b, 0),
                camp_history: {
                    refDate: monthExpectedToImportLong[0].date,
                    camp1: false,
                    camp1_date: "",
                    camp2: false,
                    camp2_date: "",
                    camp3: false,
                    camp3_date: "",
                    camp4: false,
                    camp4_date: "",
                    camp5: false,
                    camp5_date: ""
                },
                camp1: '',
                camp2: '',
                camp3: '',
                camp4: '',
                camp5: '',
                active: true
            })
        )
        return clients

    }

    async function setClientsBest_Drycleanusa(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: (typeof client["Cod.Cliente"] !== 'undefined'
                    ? client["Cod.Cliente"]
                    : "Not available"),
                name: (typeof client["Nome"] !== 'undefined' ? client["Nome"] : ''),
                birthday: (typeof client["Mês"] !== 'undefined'
                    ? '2000-' + client["Mês"] + '-15T12:00:00'
                    : ''),
                phone1: (typeof client["clcCelular"] !== 'undefined' ? client["clcCelular"] : ''),
                phone2: (typeof client["Telefone"] !== 'undefined' ? client["Telefone"] : ''),
                first_date: (typeof client["Primeira Compra"] !== 'undefined' && new Date(Date.UTC(0, 0, client["Primeira Compra"] - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client["Primeira Compra"] - 1)).toUTCString()
                    : typeof client["Primeira Compra"] !== 'undefined'
                        ? new Date(Date.UTC(0, 0, client["Primeira Compra"])).toUTCString()
                        : ''),
                tracking: {
                    last_date: (typeof client["Última Compra"] !== 'undefined' && new Date(Date.UTC(0, 0, client["Última Compra"] - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                        ? new Date(Date.UTC(0, 0, client["Última Compra"] - 1)).toUTCString()
                        : typeof client["Última Compra"] !== 'undefined'
                            ? new Date(Date.UTC(0, 0, client["Última Compra"])).toUTCString()
                            : ''),
                    ticket: (typeof client["Total Vendas"] !== 'undefined' ? client["Total Vendas"] : ''),
                    visits: (typeof client["Freq"] != 'undefined' ? client["Freq"] : ''),
                    items: 0,
                    refDate: monthExpectedToImportLong[0].date
                },
                last_date: (typeof client["Última Compra"] !== 'undefined' && new Date(Date.UTC(0, 0, client["Última Compra"] - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client["Última Compra"] - 1)).toUTCString()
                    : typeof client["Última Compra"] !== 'undefined'
                        ? new Date(Date.UTC(0, 0, client["Última Compra"])).toUTCString()
                        : ''),
                last_last_date: (typeof client["Última Compra"] !== 'undefined' && new Date(Date.UTC(0, 0, client["Última Compra"] - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client["Última Compra"] - 1)).toUTCString()
                    : typeof client["Última Compra"] !== 'undefined'
                        ? new Date(Date.UTC(0, 0, client["Última Compra"])).toUTCString()
                        : ''),
                ticket: (typeof client["Total Vendas"] !== 'undefined' ? client["Total Vendas"] : ''),
                last_ticket: (typeof client["Total Vendas"] !== 'undefined' ? client["Total Vendas"] : ''),
                last_last_ticket: 0,
                last_active_ticket: (typeof client["Total Vendas"] !== 'undefined' ? client["Total Vendas"] : ''),
                camp_history: {
                    refDate: monthExpectedToImportLong[0].date,
                    camp1: false,
                    camp1_date: "",
                    camp2: false,
                    camp2_date: "",
                    camp3: false,
                    camp3_date: "",
                    camp4: false,
                    camp4_date: "",
                    camp5: false,
                    camp5_date: ""
                },
                camp1: '',
                camp2: '',
                camp3: '',
                camp4: '',
                camp5: '',
                active: (typeof client["Última Compra"] !== 'undefined' && new Date(Date.UTC(0, 0, client["Última Compra"] - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? (moment.utc(new Date(monthExpectedToImportLong[0].date)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client["Última Compra"] - 1)).toUTCString())), 'days') < daysToBeActive ? true : false)
                    : typeof client["Última Compra"] !== 'undefined'
                        ? (moment.utc(new Date(monthExpectedToImportLong[0].date)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client["Última Compra"])).toUTCString())), 'days') < daysToBeActive ? true : false)
                        : false)
            })
        )
        return clients

    }

    async function updateDiaryConvertion(data) {

        const diariesSorted = diaries.sort(function (a, b) {
            var c = new Date(a.date_camp1);
            var d = new Date(b.date_camp1);
            return d - c;
        })

        const monthToImport = getMonthToImportDiary(diariesSorted)

        const camp1_date = (monthToImport.length !== 0 && monthToImport._id !== 0) ? monthToImport.date_camp1 : monthExpectedToImportLong[0].date
        const camp3_date = (monthToImport.length !== 0 && monthToImport._id !== 0) ? monthToImport.date_camp3 : monthExpectedToImportLong[0].date


        const monthAndYearOfCampaign = monthExpectedToImportLong[0].month //moment.utc(new Date(importDateDiaries[0].date)).format('MMMM/YYYY')

        const camp1Targets = data
            .filter(client =>
                (client.camp1 === monthAndYearOfCampaign)
                && ((client.tracking
                    .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthAndYearOfCampaign
                        && track.ticket > 0
                        && (moment.utc(new Date(track.last_date)).diff(moment.utc(new Date(camp1_date)), 'days') >= 0))).length > 0
                )
                && (getTrackingInfoBirthday(client))
            )
        const camp2Targets = data
            .filter(client =>
                client.camp2 === monthAndYearOfCampaign
                && client.last_ticket !== 0
            )

        const camp3Targets = data
            .filter(client =>
                (client.camp3 === monthAndYearOfCampaign)
                && ((client.tracking
                    .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthAndYearOfCampaign
                        && track.ticket > 0
                        && (moment.utc(new Date(track.last_date)).diff(moment.utc(new Date(camp3_date)), 'days') >= 0))).length > 0)
                && (getTrackingInfoMissyou(client))
            )

        const camp4Targets = data
            .filter(client =>
                client.camp4 === monthAndYearOfCampaign
                && client.last_ticket !== 0
            )

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const diaryVectorPATCH = {

                userId: userId,

                total_camp1_goal: camp1Targets.length,
                total_camp1_ticket: camp1Targets.reduce((n, { last_ticket }) => n + last_ticket, 0),

                total_camp2_goal: camp2Targets.length,
                total_camp2_ticket: camp2Targets.reduce((n, { last_ticket }) => n + last_ticket, 0),

                total_camp3_goal: camp3Targets.length,
                total_camp3_ticket: camp3Targets.reduce((n, { last_ticket }) => n + last_ticket, 0),

                total_camp4_goal: camp4Targets.length,
                total_camp4_ticket: camp4Targets.reduce((n, { last_ticket }) => n + last_ticket, 0)
            }

            patchDiary(diaryVectorPATCH, monthToImport._id)

        } else {
        }

        total_biwapp = camp1Targets.length + camp3Targets.length

    }

    function getTrackingInfoBirthday(client) {

        const index = client.tracking
            .findIndex(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthExpectedToImportLong[0].month
                && track.ticket > 0)

        if (index > 0) {
            if (moment.utc(new Date(client.tracking[index].last_date)).format('MMMM/YYYY') === moment.utc(new Date(client.tracking[index].refDate)).format('MMMM/YYYY')) {
                if ((moment.utc(new Date(client.tracking[index].last_date)).diff(moment.utc(new Date(client.tracking[index - 1].refDate)), 'days')) > daysToBeActive) {
                    return true
                }
            } else {
                if ((moment.utc(new Date(client.tracking[index].refDate)).diff(moment.utc(new Date(client.tracking[index - 1].refDate)), 'days')) > daysToBeActive) {
                    return true
                }
            }
            return false
        } else {
            return true
        }
    }

    function getTrackingInfoMissyou(client) {

        const index = client.tracking
            .findIndex(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthExpectedToImportLong[0].month && track.ticket > 0)

        if (index > 0) {
            if ((moment.utc(new Date(client.tracking[index].last_date)).diff(moment.utc(new Date(client.tracking[index - 1].refDate)), 'days')) > daysToBeActive) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    function getMonthToImportDiary(data) {

        const diaryMonthAndYear = monthExpectedToImportLong[0].month
        const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.date_camp1)).format('MMMM/YYYY') === diaryMonthAndYear)

        if (diaryMonthAndYear_alive.length >= 1) {
            return diaryMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    function patchDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function createClient(clientsVector) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(clientsVector)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function updateClient(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function createBiDiary(dataImportedSorted, updated, totalClientsOnTheBIWApp) {

        const monthToImport = getMonthToImport(sortByDateDiaries(biDiaries))

        const biDiaryObjPOSTorPATCH = {

            userId: userId,

            date: monthToImport.length > 0 ? monthToImport.date : moment.utc(new Date(monthExpectedToImportLong[0].date)),

            total_clients: totalClientsOnTheBIWApp.length,
            total_actives: totalClientsOnTheBIWApp.filter(client => client.active).length,
            total_repeat: updated.filter(client => client.last_ticket > 0 && client.last_last_ticket > 0).length,
            total_dnr: totalClientsOnTheBIWApp.filter(client => !client.active).length,
            total_newclient: dataImportedSorted.filter(client => moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === monthExpectedToImportLong[0].month).length,

            total_visitors: dataImportedSorted.length,
            total_biwapp: total_biwapp

        }

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            patchBiDiary(biDiaryObjPOSTorPATCH, monthToImport._id)

        } else {

            postBiDiary(biDiaryObjPOSTorPATCH)

        }

    }

    function sortByDate(data) {
        const dataSortedByDate = data.sort(function (a, b) {
            var c = new Date(a.last_date);
            var d = new Date(b.last_date);
            return d - c;
        })
        return dataSortedByDate
    }

    function sortByDateDiaries(data) {
        const dataSortedByDate = data.sort(function (a, b) {
            var c = new Date(a.date);
            var d = new Date(b.date);
            return d - c;
        })
        return dataSortedByDate
    }

    async function createImportDateDiary(data) {

        data.map((client) => {
            if ((moment.utc(new Date(monthExpectedToImportLong[0].date)).diff(moment.utc(new Date(client.last_date)), 'days')) < 365) {
                bestClientsRequest.push(client)
            }
            return 0
        })

        const newClient = data.filter(client => moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === monthExpectedToImportLong[0].month)

        const monthToImport = getMonthToImport(sortByDateDiaries(importDateDiaries))

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const bestClientsVectorPATCH = {
                date: monthToImport.date,

                birthday: {
                    total: monthToImport.birthday.total,
                    actives: monthToImport.birthday.actives,
                    importDate: monthToImport.birthday.importDate
                },

                newClient: {
                    total: newClient.length,
                    actives: newClient.length,
                    importDate: new Date().toUTCString()
                },

                bestClients: {
                    total: data.length,
                    actives: bestClientsRequest.filter(client => client.active).length,
                    importDate: new Date().toUTCString()
                }
            }

            patchImportDateDiary(bestClientsVectorPATCH, monthToImport._id)

        } else {

            const bestClientsPOST = {
                date: moment.utc(new Date(bestClientsRequest[0].last_date)),

                birthday: {
                    total: 0,
                    actives: 0,
                    importDate: new Date().toUTCString()
                },

                newClient: {
                    total: newClient.length,
                    actives: newClient.length,
                    importDate: new Date().toUTCString()
                },

                bestClients: {
                    total: data.length,
                    actives: bestClientsRequest.length,
                    importDate: new Date().toUTCString()
                }
            }

            postImportDateDiary(bestClientsPOST)

        }


        // const biDiaryTotalVisitors = {
        //     userId: userId,

        //     date: monthToImport.length > 0 ? monthToImport.date : moment.utc(new Date(monthExpectedToImportLong[0].date)),

        //     total_visitors: data.length,

        // }

        // const monthToImportBi = getMonthToImport(sortByDateDiaries(biDiaries))

        // if (monthToImportBi.length !== 0 && monthToImportBi._id !== 0) {

        //     patchBiDiary(biDiaryTotalVisitors, monthToImportBi._id)

        // } else {

        //     postBiDiary(biDiaryTotalVisitors)

        // }

    }

    function patchImportDateDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postImportDateDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function patchBiDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/biDiary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postBiDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/biDiary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getImportDateDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return c - d;
                    })
                    .map((diary) => {
                        importDateDiaries.push(diary)
                        return 0
                    })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getBiDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/biDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    biDiaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    diaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function updateCampaigns(campaigns, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    // console.log(res)
                } else {
                    console.log(res)
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getClients() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((client) => {
                    clientsAlive.push(client)
                    return 0
                })
                clientsGotten = true
                // console.log("acabou o request")
                // console.log(data.length)
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function getMonthToImport(diaries) {

        const dataToImportMonthAndYear = monthExpectedToImportLong[0].month
        const dataToImportMonthAndYear_alive = diaries.filter(bidiary => moment.utc(new Date(bidiary.date)).format('MMMM/YYYY') === dataToImportMonthAndYear)

        if (dataToImportMonthAndYear_alive.length >= 1) {
            return dataToImportMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    function getClientsToUpdate(data, allClients) {

        console.log(data)
        console.log(allClients)

        const clientsToUpdate = []

        data.filter(function (elem) {

            const cli = allClients.find(({ name, unique_identifier }) =>
                ((elem.name).toLowerCase()).replace(/[-+ ()_]/g, '') === (name.toLowerCase()).replace(/[-+ ()_]/g, '')
                && String(elem.unique_identifier) === String(unique_identifier)
            )

            if (typeof cli !== 'undefined') {

                var tracking1 = cli.tracking
                var tracking2 = elem.tracking

                const hasVisitInMonth = tracking1
                    .findIndex((track) => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === moment.utc(new Date(monthExpectedToImportLong[0].date)).format('MMMM/YYYY'))

                if (hasVisitInMonth < 0) {
                    tracking1.push(tracking2)
                } else {
                    tracking1[hasVisitInMonth].items = tracking2.items
                    tracking1[hasVisitInMonth].last_date = tracking2.last_date
                    tracking1[hasVisitInMonth].refDate = tracking2.refDate
                    tracking1[hasVisitInMonth].ticket = tracking2.ticket
                    tracking1[hasVisitInMonth].visits = tracking2.visits
                }

                clientsToUpdate.push({
                    name: cli.name,
                    unique_identifier: elem.unique_identifier,
                    tracking: tracking1,
                    camp1: cli.camp1, //for conversion
                    camp2: cli.camp2, //for conversion
                    camp3: cli.camp3, //for conversion
                    camp4: cli.camp4, //for conversion
                    camp5: cli.camp5, //for conversion
                    camp_history: cli.camp_history, //for conversion
                    last_ticket: elem.ticket,
                    last_last_ticket: cli.last_ticket,
                    ticket: cli.ticket + elem.ticket,
                    last_active_ticket: elem.ticket,
                    phone1: cli.hasWhatsapp ? cli.phone1 : elem.phone1,
                    phone2: cli.hasWhatsapp ? cli.phone2 : elem.phone2,
                    first_date: cli.first_date,
                    last_date: elem.last_date,
                    last_last_date: cli.last_date === elem.last_date ? cli.last_last_date : cli.last_date,
                    active: (moment.utc(new Date(elem.last_date)).diff(moment.utc(new Date(monthExpectedToImportLong[0].date)), 'days') <= -daysToBeActive ? false : true),
                    _id: cli._id
                })
                return cli
            } else {
                return undefined
            }
        })

        if (clientsToUpdate.length >= 1) {
            return clientsToUpdate
        } else {
            return []
        }
    }

    function getClientsToUpdateZeroTicket(allClients, data) {

        const anotherClients = []

        allClients.filter(function (elem) {

            const cli = data.find(({ name, unique_identifier }) =>
                ((elem.name).toLowerCase()).replace(/[-+ ()_]/g, '') === (name.toLowerCase()).replace(/[-+ ()_]/g, '')
                && String(elem.unique_identifier) === String(unique_identifier)
            )

            if (typeof cli !== 'undefined') {
                return cli
            } else {
                anotherClients.push({
                    name: elem.name,
                    unique_identifier: elem.unique_identifier,
                    last_ticket: 0,
                    last_last_ticket: elem.last_ticket,
                    phone1: elem.phone1,
                    last_date: elem.last_date,
                    active: (moment.utc(new Date(elem.last_date)).diff(moment.utc(new Date(monthExpectedToImportLong[0].date)), 'days') <= -daysToBeActive ? false : true),
                    _id: elem._id
                })
                return undefined
            }
        })

        if (anotherClients.length >= 1) {
            return anotherClients
        } else {
            return []
        }
    }

    function getClientsToCreate(data, allClients) {

        const clientsToCreate = data.filter((elem) => !allClients.find(({ name, unique_identifier }) =>
            ((elem.name).toLowerCase()).replace(/[-+ ()_]/g, '') === (name.toLowerCase()).replace(/[-+ ()_]/g, '')
            && String(elem.unique_identifier) === String(unique_identifier)
        ))

        if (clientsToCreate.length >= 1) {
            return clientsToCreate
        } else {
            return []
        }
    }

    return (
        <>

            <Button variant="dark" size="sm" onClick={handleClick} >
                Importar
            </Button>

            <input type="file" id="formId" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />

        </>
    )
}

export default FileInputBestClients;