import { useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Button } from 'react-bootstrap'

import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';

import { FaArrowDownShortWide } from "react-icons/fa6";

function SideBarUnits({ execute, ...props }) {

    const [show, setShow] = useState(false);
    const [state, setState] = useState({
        places: []
    })

    const handleClose = () => {
        setShow(false)
        setState(prev => ({ ...prev, error: "" }))
    }
    const handleShow = () => {
        setShow(true)
        localStorage.setItem("side", "true")
    }

    function getPlaces() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/places', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                setState({ places: data.toSorted((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) })
            })
            .catch(rejected => {
                console.log(rejected)
            });

    }

    if (localStorage.getItem("side") === "true") {
        localStorage.setItem("side", "false")
        getPlaces()
    }

    function consoleClick(userId) {
        console.log('userId: ' + userId)
        handleClose()
    }

    return (
        <>
            {localStorage.getItem("username") === 'virginia'
                ? <Button onClick={handleShow} size="sm" className="buttonSideBar" >Un. {localStorage.getItem("place_name")} <FaArrowDownShortWide /> </Button>
                : <Button disabled size="sm" className="buttonSideBar" >Un. {localStorage.getItem("place_name")} </Button>
            }

            <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: '25%' }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Unidades ({state.places.length})</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ListGroup as="ol">
                        {state.places.map(place =>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                                action style={{ cursor: 'pointer' }}
                                onClick={() => consoleClick(place.userId)}
                                disabled={false}
                                key={place.cnpj}
                            >
                                <div className="ms-2 me-auto">
                                    <div style={{ fontSize: '90%' }} className="fw-bold">{place.name}</div>
                                    <div style={{ fontSize: '80%' }}>{(place.address).split(" - ")[0]}</div>
                                    <div style={{ fontSize: '80%' }}>{place.city_state}</div>
                                </div>
                                <Badge bg={place.status === "$"
                                    ? "success"
                                    : place.status === "trial"
                                        ? "danger"
                                        : "dark"} pill>
                                    {place.status}
                                </Badge>
                            </ListGroup.Item>
                        )}


                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default SideBarUnits;