import React from "react"
import { Table } from "react-bootstrap"
import Loading from "../../Auxiliaries/Loading"
import moment from 'moment'
import 'moment/locale/pt-br'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { SparkLineChart } from '@mui/x-charts/SparkLineChart'


class ClientsMissyou extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            totalRow_last_ticket: [],
            pageReady: false,
            dateRef: moment.utc(new Date()).format(document.URL.split('/')[document.URL.split('/').length - 1])
        }

        this.daysToBeActive = 90
    }

    componentDidMount() {
        this.getClients()
        // console.log(document.URL.split('/')[document.URL.split('/').length - 1])
    }

    componentWillUnmount() {

    }

    getTrackingInfo = (client) => {

        const index = client.tracking
            .findIndex(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).format('MMMM/YYYY') && track.ticket > 0)

        if (index > 0) {
            if ((moment.utc(new Date(client.tracking[index].last_date)).diff(moment.utc(new Date(client.tracking[index - 1].refDate)), 'days')) > this.daysToBeActive) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }
    }

    getTrackingInfoIndex = (client) => {

        const index = client.tracking
            .findIndex(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).format('MMMM/YYYY') && track.ticket > 0)

        if (index >= 0) {
            return index
        } else {
            return -1
        }

    }

    getTrackingInfoIndexPrevious = (client) => {

        const index = client.tracking
            .findIndex(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).format('MMMM/YYYY') && track.ticket > 0)

        if (index > 0) {
            return true
        } else {
            return false
        }

    }

    hasVisitedLastActiveMonth = (client) => {

        const last_date_monthYear = moment.utc(new Date(client.last_date)).subtract(3, 'month')
        const last_last_date_monthYear = moment.utc(new Date(client.last_last_date))

        if (last_date_monthYear.format('YYYY') === last_last_date_monthYear.format('YYYY')) {
            //msm ano
            if (last_date_monthYear.format('MM') - last_last_date_monthYear.format('MM') <= this.daysToBeActive / 30) {
                return true
            }
        } else if (last_date_monthYear.format('YYYY') > last_last_date_monthYear.format('YYYY')) {
            //1 ano p frente ->  01/2025  12/2025
            if (last_last_date_monthYear.format('MM') - last_date_monthYear.format('MM') >= 12 - (this.daysToBeActive / 30)) {
                return true
            }
        } else {
            //1 ano p tras - provavelmente errado
            return false
        }
        return false

    }

    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")
        var clientLength = 999999999

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {

                const clientFiltered = data
                    .filter(client =>
                        ((client.camp_history
                            .filter(camp => moment.utc(new Date(camp.refDate)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).format('MMMM/YYYY')
                                && camp.camp3)).length > 0)
                        && ((client.tracking
                            .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === moment.utc(new Date(this.state.dateRef)).format('MMMM/YYYY')
                                && track.ticket > 0
                                && (moment.utc(new Date(track.last_date)).diff(moment.utc(new Date(this.state.dateRef)), 'days') >= 0))).length > 0)
                        && (this.getTrackingInfo(client))
                        && (client.hasWhatsapp)
                    )
                    .toSorted(function (a, b) {
                        var c = new Date(a.last_last_date);
                        var d = new Date(b.last_last_date);
                        return c - d;
                    })

                const mesCamp = 'dezembro/2024'
                const mesJurosCamp = 'janeiro/2025'

                const jurosCamp = data
                    .filter(client =>
                        ((client.camp_history
                            .filter(camp => moment.utc(new Date(camp.refDate)).format('MMMM/YYYY') === mesCamp
                                && camp.camp3)).length > 0)
                        && ((client.tracking
                            .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === mesCamp
                                && track.ticket > 0
                                && (moment.utc(new Date(track.last_date)).diff(moment.utc(new Date(this.state.dateRef)), 'days') >= 0))).length > 0
                            && (client.tracking
                                .filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === mesJurosCamp
                                    && track.ticket > 0
                                    && (moment.utc(new Date(track.last_date)).diff(moment.utc(new Date(this.state.dateRef)), 'days') >= 0))).length > 0)
                        && client.camp3 !== mesJurosCamp
                        && this.hasVisitedLastActiveMonth(client)
                        && this.getTrackingInfo(client)
                        && client.hasWhatsapp
                    )
                    .toSorted(function (a, b) {
                        var c = new Date(a.last_last_date);
                        var d = new Date(b.last_last_date);
                        return c - d;
                    })

                console.log(jurosCamp)

                const jurosTicketAcc = []
                jurosCamp.map(client => {
                    const jurosTicket = client.tracking.filter(track => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === mesJurosCamp
                        && track.ticket > 0)
                    jurosTicketAcc.push(jurosTicket[0].ticket)
                    return 0
                })
                console.log(jurosTicketAcc.reduce((a, b) => a + b, 0))

                
                const clientsWTracking = []

                clientFiltered.map(client => {
                    const tracking = client.tracking
                        .toSorted(function (a, b) {
                            var c = new Date(a.refDate);
                            var d = new Date(b.refDate);
                            return c - d;
                        })

                    const trackingInsideForMonth = []
                    const trackingInsideForVisits = []
                    const trackingInsideForColor = []

                    const trackingIndex = this.getTrackingInfoIndex(client)
                    const trackingIndexPrevious = this.getTrackingInfoIndexPrevious(client)

                    const trackingTicket = trackingIndex >= 0 ? client.tracking[trackingIndex].ticket : 0
                    const trackingLastVisit = trackingIndex >= 0 ? client.tracking[trackingIndex].last_date : 0
                    const trackingLastLastVisit = trackingIndexPrevious ? client.tracking[trackingIndex - 1].refDate : 0

                    //GRAFICO
                    const graphicTotalMonths = 12
                    for (let index = 1; index <= graphicTotalMonths; index++) {
                        //DATA
                        const monthVisit = moment.utc(new Date()).subtract(index, "month")
                        const hasVisitInMonth = tracking
                            .filter((track) => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthVisit.format('MMMM/YYYY'))
                        if (hasVisitInMonth.length > 0) {
                            trackingInsideForMonth.push(monthVisit.format('MMMM/YYYY'))
                            trackingInsideForVisits.push(hasVisitInMonth[0].visits)
                        } else {
                            trackingInsideForMonth.push(monthVisit.format('MMMM/YYYY'))
                            trackingInsideForVisits.push(0)
                        }
                        //COLOR
                        if ((moment.utc(new Date(trackingLastVisit)).diff(moment.utc(new Date(monthVisit)), 'days')) < 0
                            && moment.utc(new Date(trackingLastVisit)).format('MM/YYYY') !== moment.utc(new Date(monthVisit)).format('MM/YYYY')) {
                            trackingInsideForColor.push('black') //apos mes ref
                        } else {
                            trackingInsideForColor.push('#24d366') //mes ref para tras
                        }
                    }

                    clientsWTracking.push({
                        _id: client._id,
                        name: client.name,
                        phone1: client.phone1,
                        first_date: client.first_date,
                        last_date: trackingLastVisit,
                        last_last_date: trackingLastLastVisit,
                        birthday: client.birthday,
                        last_ticket: trackingTicket,
                        last_last_ticket: client.last_last_ticket,
                        active: client.active,
                        ticket: trackingTicket,
                        tracking: {
                            months: trackingInsideForMonth.reverse(),
                            visits: trackingInsideForVisits.reverse(),
                            color: trackingInsideForColor.reverse()
                        }
                    })
                    return 0
                })

                this.setState({
                    clients: clientsWTracking,
                    totalRow_last_ticket: clientsWTracking
                        .reduce((n, { last_ticket }) => n + last_ticket, 0)
                })

                clientLength = data.length
            })
            .catch(rejected => {
                console.log(rejected);
            })
            .finally(data => {
                if (clientLength !== 999999999) {
                    this.setState({ pageReady: true })
                }
            })

    }

    uppercaseEachWords = (sentence) => {

        if (typeof sentence !== 'undefined') {
            const words = sentence.split(' ')

            return words.map((word) => {
                if (word.length > 1) {
                    return word[0] + word.substring(1).toLowerCase()
                } else {
                    return word[0]
                }
            }).join(" ")
        }

        return sentence
    }

    phoneNumberCorrection = (phone) => {

        const onlyNum = phone.replace(/[-+ ()_]/g, '')

        if (typeof onlyNum !== 'undefined' && onlyNum !== '') {

            //already ok - BRAZIL
            if (onlyNum[0] === '5' && onlyNum[1] === '5') {
                return '+' + onlyNum.slice(0, 2)
                    + ' ' + onlyNum.slice(2, 4)
                    + ' ' + onlyNum.slice(4, onlyNum.length - 4)
                    + ' ' + onlyNum.slice(onlyNum.length - 4, onlyNum.length)
            }
            //check DDI
            if (onlyNum[0] === '+') {
                return onlyNum
            }

            //remove first zero DDD
            if (onlyNum[0] === '0') {
                if (onlyNum.slice(1).length >= 10) { //correct number already
                    return '+55 '
                        + (onlyNum.slice(1)).slice(0, 2)
                        + ' '
                        + ((onlyNum.slice(1)).slice(2)).slice(0, ((onlyNum.slice(1)).slice(2)).length - 4)
                        + ' '
                        + ((onlyNum.slice(1)).slice(2)).slice(((onlyNum.slice(1)).slice(2)).length - 4, ((onlyNum.slice(1)).slice(2)).length)
                } else { //incorrect number
                    return ''
                }
            } else {
                if (onlyNum.length >= 10) { //correct number already
                    return '+55 '
                        + onlyNum.slice(0, 2)
                        + ' '
                        + (onlyNum.slice(2)).slice(0, (onlyNum.slice(2)).length - 4)
                        + ' '
                        + (onlyNum.slice(2)).slice((onlyNum.slice(2)).length - 4, (onlyNum.slice(2)).length)
                } else { // without DDD
                    // console.log('INCORRETO: ' + onlyNum)
                    return '+55 ' + localStorage.getItem("ddd") + ' '
                        + onlyNum.slice(0, onlyNum.length - 4)
                        + ' '
                        + onlyNum.slice(onlyNum.length - 4, onlyNum.length)
                }
            }
        }
        return ''
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.pageReady
                        ?
                        <>
                            <h3>Sentimos sua falta - conversão {moment(new Date(this.state.dateRef)).format('MMMM/YYYY')}</h3>
                            ({this.state.clients.length} clientes) - Campanha enviada em {moment(new Date(this.state.dateRef)).format('DD/MM/YYYY HH:mm')}

                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Telefone</th>
                                        <th>Primeira visita</th>
                                        <th>Penúltima visita</th>
                                        <th>Última visita</th>
                                        <th>Gasto</th>
                                        <th>Visitas</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.clients
                                            .map((client) =>
                                                <tr key={client._id}>
                                                    <td>{this.uppercaseEachWords(client.name)}</td>
                                                    <td>{this.phoneNumberCorrection(client.phone1)}</td>
                                                    <td>{moment.utc(new Date(client.first_date)).format('DD/MM/YYYY')}</td>
                                                    <td style={{ color: '#ed4545' }}>{client.last_last_date ? moment.utc(new Date(client.last_last_date)).format('MMM/YYYY') : '-'}</td>
                                                    <td style={{ color: '#1caf55' }}>{client.last_date ? moment.utc(new Date(client.last_date)).format('DD/MM/YYYY') : '-'}</td>
                                                    <td style={{ color: '#1caf55' }}>R$ {(typeof client.last_ticket !== 'undefined') ? (client.last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</td>
                                                    <td>
                                                        <Stack direction="row" >
                                                            <Box sx={{ flexGrow: 1 }}>
                                                                <SparkLineChart
                                                                    plotType="bar"
                                                                    data={client.tracking.visits}
                                                                    height={30}
                                                                    width={80}
                                                                    // colors={['#24d366']}
                                                                    showTooltip
                                                                    showHighlight
                                                                    xAxis={{
                                                                        scaleType: 'band',
                                                                        data: client.tracking.months,
                                                                        colorMap: {
                                                                            type: 'ordinal',
                                                                            colors: client.tracking.color,
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Stack>
                                                    </td>
                                                </tr>

                                            )
                                    }
                                    <tr>
                                        <td><b>TOTAL</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>R$ {(typeof this.state.totalRow_last_ticket !== 'undefined') ? (this.state.totalRow_last_ticket.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')) : Number(0).toFixed(2)}</b></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default ClientsMissyou;