import React from "react"
import 'moment/locale/pt-br'
import { Text } from 'react-native'
import { Card, Row, Col, Button, Form } from "react-bootstrap"
import { RemoveScroll } from 'react-remove-scroll'


class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: [],
            username: '',
            password: '',
            loginMsg: '',
            isMobile: (window.innerWidth < 700)
        }
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    handleClick = () => {
        this.tryLogin()
    }

    tryLogin = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const user = {
            username: this.state.username,
            password: this.state.password
        }

        fetch(BASE_URL + '/auth/login', {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .then(async data => {
                if (data.error) {
                    this.setState({ loginMsg: data.error })
                } else {
                    localStorage.setItem("side", "false")
                    localStorage.setItem("nekb", data.token)
                    localStorage.setItem("resd", data.userAlive._id)
                    localStorage.setItem("nekw", data.userAlive.places.token)
                    localStorage.setItem("cenaw", data.userAlive.places.instance_id)
                    localStorage.setItem("username", data.userAlive.username)
                    localStorage.setItem("camp", "")
                    if (data.userAlive.places.plan === "basic") {
                        localStorage.setItem("nalp", "300")
                    }
                    if (data.userAlive.places.plan === "standard") {
                        localStorage.setItem("nalp", "400")
                    }
                    if (data.userAlive.places.plan === "premium") {
                        localStorage.setItem("nalp", "900")
                    }
                    localStorage.setItem("nim", data.userAlive.places.camp_miss_you_min_days)
                    localStorage.setItem("xam", data.userAlive.places.camp_miss_you_max_days)
                    localStorage.setItem("act", data.userAlive.places.camp_active_days)
                    localStorage.setItem("pdv", data.userAlive.places.pdv)
                    localStorage.setItem("ddd", (data.userAlive.places.phone).slice(3, 5))
                    localStorage.setItem("place_name", data.userAlive.places.name)

                    localStorage.setItem("tabPath", "home")
                    await this.sleep(1000)
                    window.dispatchEvent(new Event("nekb"))
                }
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    onKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.tryLogin()
        }
    }

    render() {
        return (
            <><RemoveScroll>
                {this.state.isMobile
                    ? <div style={{
                        backgroundImage: `url("/images/login_bg_mobile.png")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100vw',
                        height: '100vh'
                    }}
                    >
                        <div style={{
                            display: 'grid',
                            placeItems: 'center',
                            height: '130vh'
                        }}>
                            <Row xs={1} md={1} className="g-4" >
                                <Col>
                                    <div style={{ width: 370, height: 500, margin: '0 auto' }}>
                                        <Card border="" className="text-left" >
                                            <Card.Body>
                                                <Card.Title>Bem-vindo a {'  '}<img
                                                    alt=""
                                                    src="./images/biwapp-logo-black.png"
                                                    width="100px"
                                                    height="auto"
                                                />
                                                </Card.Title>
                                                <Text style={{ color: 'gray' }}>
                                                    Entre com sua conta para começar
                                                </Text>

                                                <Form>
                                                    <Form.Group as={Row} className="mb-3" style={{ width: '106%' }}>
                                                        <Form.Label>
                                                            <Form.Control type="text" placeholder="Email cadastrado" onChange={e => this.setState({ username: e.target.value })} onKeyPress={this.onKeyDown} />
                                                        </Form.Label>

                                                        <Form.Label>
                                                            <Form.Control type="password" placeholder="Senha" onChange={e => this.setState({ password: e.target.value })} onKeyPress={this.onKeyDown} />
                                                            <span className="CardFooter" style={{ color: 'red' }}>{this.state.loginMsg}</span>
                                                        </Form.Label>

                                                        <span className="CardFooter" style={{ color: 'gray', position: "relative", bottom: 0, left: 190 }}>
                                                            <a style={{ color: 'gray' }} href="https://api.whatsapp.com/send/?phone=5511939501767">Esqueceu sua senha?</a>
                                                        </span>

                                                    </Form.Group>

                                                </Form>

                                                <Button variant="dark" size="" style={{ width: '100%' }} onClick={() => this.handleClick()} >Entrar</Button>

                                            </Card.Body>

                                            <div style={{ height: 50, margin: '0 auto' }}>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Não possui uma conta?{' '}
                                                    <a style={{ color: 'gray' }} href="https://api.whatsapp.com/send/?phone=5511939501767">Entre em contato</a>
                                                </span>
                                            </div>

                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div >
                    : <div className="LoginPage" style={{
                        backgroundImage: `url("/images/login_bg.png")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: '100vw',
                        height: '100vh'
                    }}
                    >
                        <div className="" style={{
                            display: 'grid',
                            placeItems: 'center',
                            height: '110vh'
                        }}>
                            <Row xs={1} md={1} className="g-4" >
                                <Col>
                                    <div style={{ width: 400, height: 500, margin: '0 auto' }}>
                                        <Card border="" className="text-left" >
                                            <Card.Body>
                                                <Card.Title>Bem-vindo a {'  '}<img
                                                    alt=""
                                                    src="./images/biwapp-logo-black.png"
                                                    width="100px"
                                                    height="auto"
                                                />
                                                </Card.Title>
                                                <Text style={{ color: 'gray' }}>
                                                    Entre com sua conta para começar
                                                </Text>
                                                <Card.Text>
                                                </Card.Text>

                                                <Form>
                                                    <Form.Group as={Row} className="mb-3" style={{ width: '106%' }}>
                                                        <Form.Label>
                                                            <Form.Control type="text" placeholder="Email cadastrado" onChange={e => this.setState({ username: e.target.value })} onKeyPress={this.onKeyDown} />
                                                        </Form.Label>

                                                        <Form.Label>
                                                            <Form.Control type="password" placeholder="Senha" onChange={e => this.setState({ password: e.target.value })} onKeyPress={this.onKeyDown} />
                                                            <span className="CardFooter" style={{ color: 'red' }}>{this.state.loginMsg}</span>
                                                        </Form.Label>

                                                        <span className="CardFooter" style={{ color: 'gray', position: "relative", bottom: 0, left: 220 }}>
                                                            <a style={{ color: 'gray' }} href="https://api.whatsapp.com/send/?phone=5511939501767">Esqueceu sua senha?</a>
                                                        </span>

                                                    </Form.Group>

                                                </Form>

                                                <Button variant="dark" size="" style={{ width: '100%' }} onClick={() => this.handleClick()} >Entrar</Button>

                                            </Card.Body>

                                            <div style={{ height: 50, margin: '0 auto' }}>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Não possui uma conta?{' '}
                                                    <a style={{ color: 'gray' }} href="https://api.whatsapp.com/send/?phone=5511939501767">Entre em contato</a>
                                                </span>
                                            </div>

                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div >}
            </RemoveScroll></>
        )
    }

}

export default Login;