import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import React from "react"
import Home from './components/Tabs/Home'
import Campaigns from './components/Tabs/Campaigns'
import Messaging from './components/Tabs/Messaging'
import ImportData from './components/Tabs/ImportData'
import Bi from './components/Tabs/Bi'
import Clients from './components/Tabs/Clients'
import Configs from './components/Tabs/Configs'
import Login from './components/Login'
import ClientsBirthday from './components/Tabs/SubTabs/ClientsBirthday'
import ClientsNew from './components/Tabs/SubTabs/ClientsNew'
import ClientsMissyou from './components/Tabs/SubTabs/ClientsMissyou'
import { BrowserRouter, Link, Route, Routes, Navigate } from 'react-router-dom'
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap'

import SideBarUnits from "./components/Tabs/SubTabs/SideBarUnits"

class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isAuth: false,
      lastPage: '/login',
      isMobile: (window.innerWidth < 700),
      hasBiTab: false
    }

  }

  componentDidMount() {
    this.checkIsAuth()
    this.checkLocalStorage()

  }

  componentWillUnmount() {

  }

  checkIsAuth = () => {
    if (localStorage.getItem("nekb")) {
      this.setState({ isAuth: true })
      this.setState({ hasBiTab: localStorage.getItem("nalp") !== "300" ? true : false })
      this.setState({ lastPage: '/' + document.URL.split('/')[document.URL.split('/').length - 1] })
    } else {
      this.setState({ isAuth: false })
      this.setState({ hasBiTab: false })
      localStorage.removeItem("side")
      localStorage.removeItem("nekb")
      localStorage.removeItem("resd")
      localStorage.removeItem("nekw")
      localStorage.removeItem("cenaw")
      localStorage.removeItem("username")
      localStorage.removeItem("camp")
      localStorage.removeItem("nalp")
      localStorage.removeItem("nim")
      localStorage.removeItem("xam")
      localStorage.removeItem("act")
      localStorage.removeItem("pdv")
      localStorage.removeItem("ddd")
      localStorage.removeItem("place_name")
      localStorage.removeItem("tabPath")
      this.setState({ isAuth: false })
    }
  }

  checkLocalStorage = () => {
    window.addEventListener('nekb', () => {
      this.setState({ isAuth: true })
      this.setState({ hasBiTab: localStorage.getItem("nalp") !== "300" ? true : false })
      window.location.href = "/"
    })
  }

  tryLogout = () => {
    this.setState({ isAuth: false })
    this.setState({ hasBi: false })
    localStorage.removeItem("side")
    localStorage.removeItem("nekb")
    localStorage.removeItem("resd")
    localStorage.removeItem("nekw")
    localStorage.removeItem("cenaw")
    localStorage.removeItem("username")
    localStorage.removeItem("camp")
    localStorage.removeItem("nalp")
    localStorage.removeItem("nim")
    localStorage.removeItem("xam")
    localStorage.removeItem("act")
    localStorage.removeItem("pdv")
    localStorage.removeItem("ddd")
    localStorage.removeItem("place_name")
    localStorage.removeItem("tabPath")
  }

  tabSelector = (path) => {
    localStorage.setItem("tabPath", path)
  }


  render() {
    return (
      <div className="App" >

        <BrowserRouter>

          {this.state.isAuth &&
            <Navbar bg="dark" data-bs-theme="dark" fixed="top" show="false">
              <Container style={{
                maxWidth: "100%",
                margin: 0,
                padding: 0,
                paddingLeft: 20,
                paddingRight: 20
              }}>

                {this.state.isMobile &&
                  <Nav className="me-auto" variant="underline">
                    <NavDropdown title="Menu">
                      <NavDropdown.Item><Nav.Link as={Link} to="/">Início</Nav.Link></NavDropdown.Item>
                      <NavDropdown.Item><Nav.Link as={Link} to="/campaigns">Campanhas</Nav.Link></NavDropdown.Item>
                      <NavDropdown.Item><Nav.Link as={Link} to="/messaging">Mensageria</Nav.Link></NavDropdown.Item>
                      {this.state.hasBiTab &&
                        <NavDropdown.Item><Nav.Link as={Link} to="/bi">B.I.</Nav.Link></NavDropdown.Item>
                      }
                      <NavDropdown.Item><Nav.Link as={Link} to="/clients">Clientes</Nav.Link></NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item><Nav.Link as={Link} to="/configs">Configurações</Nav.Link></NavDropdown.Item>
                      <NavDropdown.Item><Nav.Link as={Link} onClick={() => { this.tryLogout() }}>Sair</Nav.Link></NavDropdown.Item>
                    </NavDropdown>
                  </Nav>}

                {!this.state.isMobile &&
                  <Navbar.Brand href="/">
                    <img
                      alt=""
                      src="./images/biwapp-logo-white.png"
                      width="90px"
                      height="auto"
                      className="d-inline-block align-center"
                    // className="d-inline-block align-top"
                    />
                  </Navbar.Brand>}
                {!this.state.isMobile &&
                  <Nav className="me-auto" variant="underline" defaultActiveKey={localStorage.getItem("tabPath")}>
                    <Nav.Link onClick={() => {this.tabSelector("home")}} eventKey="home" as={Link} to="/">Início</Nav.Link>
                    <Nav.Link onClick={() => {this.tabSelector("campaigns")}} eventKey="campaigns" as={Link} to="/campaigns">Campanhas</Nav.Link>
                    <Nav.Link onClick={() => {this.tabSelector("messaging")}} eventKey="messaging" as={Link} to="/messaging">Mensageria</Nav.Link>
                    {this.state.hasBiTab &&
                      <Nav.Link onClick={() => {this.tabSelector("campaigns")}} eventKey="bi" as={Link} to="/bi">B.I.</Nav.Link>
                    }
                    <Nav.Link onClick={() => {this.tabSelector("clients")}} eventKey="clients" as={Link} to="/clients">Clientes</Nav.Link>
                    <Nav.Link onClick={() => {this.tabSelector("importdata")}} eventKey="importdata" as={Link} to="/importdata">Importar dados</Nav.Link>
                    <Nav.Link onClick={() => {this.tabSelector("configs")}} eventKey="configs" as={Link} to="/configs">Configurações</Nav.Link>
                  </Nav>}

                <Nav className="justify-content-end" variant="underline">
                  {!this.state.isMobile && <SideBarUnits execute={"true"}></SideBarUnits>}
                  {!this.state.isMobile && <Nav.Link as={Link} onClick={() => { this.tryLogout() }}>Sair</Nav.Link>}
                  {this.state.isMobile && <>
                    <Navbar.Brand href="/">
                      <img
                        alt=""
                        src="./images/biwapp-logo-white.png"
                        width="90px"
                        height="auto"
                        className="d-inline-block"
                      />
                    </Navbar.Brand>
                  </>}
                </Nav>

              </Container>
            </Navbar>}

          {!this.state.isAuth && <Navigate to="/login" />}
          {this.state.isAuth && <Navigate to={this.lastPage} />}

          <Routes>
            <Route path='/login' index element={<Login />}></Route>
            <Route path='/' index element={<Home />}></Route>
            <Route path='/campaigns' index element={<Campaigns />}></Route>
            <Route path='/messaging' index element={<Messaging />}></Route>
            {this.state.hasBiTab &&
              <Route path='/bi' index element={<Bi />}></Route>
            }
            <Route path='/clients' index element={<Clients />}></Route>
            <Route path='/importdata' index element={<ImportData />}></Route>
            <Route path='/configs' index element={<Configs />}></Route>

            <Route path='/clientsbirthday/:id' index element={<ClientsBirthday />}></Route>
            <Route path='/clientsnew/:id' index element={<ClientsNew />}></Route>
            <Route path='/clientsmissyou/:id' index element={<ClientsMissyou />}></Route>
          </Routes>

        </BrowserRouter>

      </div>
    )
  }

}

export default App;
