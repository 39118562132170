import React from "react"
import { Card, CardGroup, Badge, Image, Col, Row } from 'react-bootstrap'
import Loading from "../Auxiliaries/Loading"
import 'moment/locale/pt-br'
// import ModalPwd from "./SubTabs/ModalPwd"


class Configs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            diaries: [],
            today: [],
            img_qrcode: '',

            userId: localStorage.getItem("resd"),
            username: '',
            name: '',

            place_name: '',
            place_address: '',
            place_city_state: '',
            place_cnpj: '',
            place_phone: '',
            place_userPhone: '',
            place_email: '',

            wapp_status: '',
            wapp_info_number: '',
            wapp_info_name: '',
            wapp_info_photo: '',
            wapp_info_photo_redir_phone_img: '',

            pageReady: false,
            hasQrCode: false,

        }

        this.onPage = true
    }

    componentDidMount() {
        this.getUser(this.state.userId)
        this.getPlace(this.state.userId)
        this.checkStatus()
    }

    componentWillUnmount() {
        this.onPage = false
    }

    getUser = (id) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/user/' + id, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    username: data.username,
                    name: data.name,
                    place_userPhone: data.phone
                })
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    getPlace = (userId) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/places/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    place_name: data.name,
                    place_cnpj: data.cnpj,
                    place_phone: data.phone,
                    place_email: data.email,
                    place_address: data.address,
                    place_city_state: data.city_state
                })
            })
            .catch(rejected => {
                console.log(rejected)
            });

    }

    formatCPForCNPJ = (data) => {

        const leanData = data.toString()

        if (leanData.length === 11) { //cpf
            return leanData.slice(0, 3) + '.'
                + leanData.slice(3, 6) + '.'
                + leanData.slice(6, 9) + '-'
                + leanData.slice(9)
        }
        if (leanData.length === 14) { //cnpj
            return leanData.slice(0, 2) + '.'
                + leanData.slice(2, 5) + '.'
                + leanData.slice(5, 8) + '/'
                + leanData.slice(8, 12) + '-'
                + leanData.slice(12)
        }
        return '' // invalid format
    }

    formatUserPhoneNumber = (data) => {

        const leanData = data.toString()

        if (leanData.length === 13) {
            return leanData.slice(0, 3) + ' '
                + leanData.slice(3, 5) + ' '
                + leanData.slice(5, 9) + '-'
                + leanData.slice(9, 13)
        }
        if (leanData.length === 14) {
            return leanData.slice(0, 3) + ' '
                + leanData.slice(3, 5) + ' '
                + leanData.slice(5, 10) + '-'
                + leanData.slice(10, 14)
        }

    }

    checkStatus = () => {

        if (this.onPage) {
            this.getWappStatus()

            setTimeout(() => {
                this.checkStatus()
            }, 3000)
        } else {
            return
        }
    }

    getWappStatus = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch('https://waapi.app/api/v1/instances/' + instance_id + '/client/status', {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === "success") {
                    this.setState({
                        wapp_status: data.clientStatus.instanceStatus
                    })
                    if (data.clientStatus.instanceStatus === "ready") {
                        this.getClientInfo()
                        if (this.state.wapp_info_photo_redir_phone_img === '' && this.state.place_phone !== '') {
                            this.getRedirWappImg((this.state.place_phone).replace('+', ''))
                        }
                        this.setState({ hasQrCode: false })
                    } else if (data.clientStatus.instanceStatus === "booting") {
                        this.setState({ pageReady: true })
                    } else {
                        this.getQrCode()
                        this.setState({ hasQrCode: true })
                    }
                }
            })
            .catch(err => console.error('error:' + err))

    }

    getQrCode = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/qr", {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    img_qrcode: data.qrCode.status !== 'error'
                        ? data.qrCode.data.qr_code
                        : ''
                })
                this.setState({ pageReady: true })
            })
            .catch(err => console.error('error:' + err))
    }

    getClientInfo = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/me", {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    wapp_info_name: data.me.data.displayName,
                    wapp_info_number: data.me.data.formattedNumber,
                    wapp_info_photo: data.me.data.profilePicUrl
                })
                this.setState({ pageReady: true })
            })
            .catch(err => console.error('error:' + err))
    }

    getRedirWappImg = (redirWappPhone) => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")
        const phone = this.removeNinethDigit(redirWappPhone)

        fetch("https://waapi.app/api/v1/instances/" + instance_id + "/client/action/get-profile-pic-url", {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            },
            body: JSON.stringify({ contactId: phone + '@c.us' })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    wapp_info_photo_redir_phone_img: data.data.data.profilePicUrl
                })
            })
            .catch(err => console.error('error:' + err))
    }

    removeNinethDigit = (phone) => {

        const clearPhone = phone.replace(/[-+ ()_]/g, '')
        const ddd = clearPhone.slice(2, 4)

        if (Number(ddd) >= 11 && Number(ddd) <= 28) {  //sp 11-19, rj 21,22,24, es 27-28
            return clearPhone
        }

        console.log('DDD para RETIRAR 9')
        if (phone.length === 13) {
            return phone.slice(0, 4) + phone.slice(5, 13)
        }

        return clearPhone
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.pageReady
                        ?
                        <>
                            <h3>Configuração do WhatsApp</h3>

                            <CardGroup>
                                {localStorage.getItem("username") === 'virginia'
                                    ? <Card border="" className="text-center">
                                        <Card.Body>
                                            {this.state.img_qrcode !== '' && this.state.wapp_status !== 'ready'
                                                ? <>
                                                    <Card.Title>Escaneie o QR Code</Card.Title>
                                                    <Card.Img variant="top" src={this.state.img_qrcode}
                                                        style={{
                                                            width: '62%',
                                                            alignSelf: 'center'
                                                        }} />
                                                    <Card.Text>
                                                        <Badge bg="warning" text="dark">Aguardando</Badge>
                                                    </Card.Text>
                                                </>
                                                : <>
                                                    <Card.Title>QR Code em uso</Card.Title>
                                                    <Card.Img variant="top" src='./images/qrcode_desabled.png'
                                                        style={{
                                                            width: '62%',
                                                            alignSelf: 'center'
                                                        }} />
                                                    <Card.Text>
                                                        <Badge bg="" style={{ backgroundColor: '#24d366' }}>Conectado</Badge>
                                                    </Card.Text>
                                                </>
                                            }
                                        </Card.Body>
                                    </Card>
                                    : ''
                                }


                                <Card border="" className="text-center">
                                    <Card.Body>
                                        <Card.Title>Informações do WhatsApp</Card.Title>
                                        {this.state.img_qrcode === '' && this.state.wapp_status === 'ready'
                                            ?
                                            <Row xs={1} md={2} className="g-4">
                                                <Col>
                                                    <br></br>
                                                    <Card border="success" className="text-center">
                                                        <Card.Body>
                                                            <Card.Text>
                                                                <b>Enviar como</b>
                                                                <br></br>
                                                                <br></br>
                                                                <Image src={this.state.wapp_info_photo} roundedCircle
                                                                    style={{
                                                                        width: '25%',
                                                                        alignSelf: 'center'
                                                                    }} />
                                                                <br></br>
                                                                <br></br>
                                                                <b>{this.state.wapp_info_name}</b>
                                                                <br></br>
                                                                <b>{this.state.wapp_info_number}</b>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <br></br>
                                                    <Card border="success" className="text-center">
                                                        <Card.Body>
                                                            <Card.Text>
                                                                <b>Redirecionar para</b>
                                                                <br></br>
                                                                <br></br>
                                                                <Image src={this.state.wapp_info_photo_redir_phone_img !== '' && this.state.wapp_info_photo_redir_phone_img !== 'undefined'
                                                                    ? this.state.wapp_info_photo_redir_phone_img
                                                                    : './images/whatsapp_logo.png'}
                                                                    roundedCircle
                                                                    style={{
                                                                        width: '25%',
                                                                        alignSelf: 'center'
                                                                    }} />
                                                                <br></br>
                                                                <br></br>
                                                                <b>WhatsApp da Loja</b>
                                                                <br></br>
                                                                <b>{this.formatUserPhoneNumber(this.state.place_phone)}</b>
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>

                                            : this.state.img_qrcode === '' && this.state.wapp_status === 'booting'
                                                ?
                                                <Row xs={1} md={2} className="g-4">
                                                    <Col>
                                                        <br></br>
                                                        <Card border="danger" className="text-center">
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    Já estamos verificando a conexão do número BIWAPP
                                                                    <br></br>
                                                                    Caso desejar entrar em contato:
                                                                    <br></br>
                                                                    BIWAPP WhatsApp
                                                                    <br></br>
                                                                    +55 11 93950-1767
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <br></br>
                                                        <Card border="success" className="text-center">
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    <b>Redirecionar para</b>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <Image src={this.state.wapp_info_photo_redir_phone_img !== '' && this.state.wapp_info_photo_redir_phone_img !== 'undefined'
                                                                        ? this.state.wapp_info_photo_redir_phone_img
                                                                        : './images/whatsapp_logo.png'}
                                                                        roundedCircle
                                                                        style={{
                                                                            width: '25%',
                                                                            alignSelf: 'center'
                                                                        }} />
                                                                    <br></br>
                                                                    <br></br>
                                                                    <b>WhatsApp da Loja</b>
                                                                    <br></br>
                                                                    <b>{this.formatUserPhoneNumber(this.state.place_phone)}</b>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                                : <Row xs={1} md={2} className="g-4">
                                                    <Col>
                                                        <br></br>
                                                        <Card border="warning" className="text-center">
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    <br></br>
                                                                    Aguardando escanear o QR Code para sincronizar o número de WhatsApp BIWAPP
                                                                    <br></br>
                                                                    Apenas aguarde
                                                                    <br></br>
                                                                    <br></br>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col>
                                                        <br></br>
                                                        <Card border="success" className="text-center">
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    <b>Redirecionar para</b>
                                                                    <br></br>
                                                                    <br></br>
                                                                    <Image src={this.state.wapp_info_photo_redir_phone_img !== '' && this.state.wapp_info_photo_redir_phone_img !== 'undefined'
                                                                        ? this.state.wapp_info_photo_redir_phone_img
                                                                        : './images/whatsapp_logo.png'}
                                                                        roundedCircle
                                                                        style={{
                                                                            width: '25%',
                                                                            alignSelf: 'center'
                                                                        }} />
                                                                    <br></br>
                                                                    <br></br>
                                                                    <b>WhatsApp da Loja</b>
                                                                    <br></br>
                                                                    <b>{this.formatUserPhoneNumber(this.state.place_phone)}</b>
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                        }
                                    </Card.Body>
                                </Card>

                                <Card border="" className="text-center">

                                    <Card.Body>
                                        <Card.Title>Informações da conta</Card.Title>
                                        <br></br>
                                        <Card.Text><b>{this.state.place_name} - {this.state.place_city_state}</b>
                                            <br></br><b>{this.state.place_address}</b></Card.Text>
                                        <Card.Text>{this.formatCPForCNPJ(this.state.place_cnpj)}</Card.Text>
                                        <br></br>
                                        <Card.Text>{this.state.name}
                                            <br></br>{this.formatUserPhoneNumber(this.state.place_userPhone)}
                                            <br></br>{this.state.place_email}</Card.Text>
                                        <br></br>
                                        {/* <ModalPwd></ModalPwd> */}
                                    </Card.Body>

                                </Card>

                            </CardGroup>

                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Configs;