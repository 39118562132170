import React from "react"
import { Form, Button, Card, Alert, CardGroup } from "react-bootstrap"
import moment from 'moment'
import 'moment/locale/pt-br'
import Message from "../Auxiliaries/Message"
import Loading from "../Auxiliaries/Loading"


class Campaigns extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            campaigns: [],
            today: [],

            img_camp1: '',
            msg_camp1: '',
            status_camp1: '',
            btn_camp1: true,
            alert_camp1: false,
            wapp_camp1: '',

            img_camp3: '',
            msg_camp3: '',
            status_camp3: '',
            btn_camp3: true,
            alert_camp3: false,
            wapp_camp3: '',

            img_camp5: '',
            msg_camp5: '',
            status_camp5: '',
            btn_camp5: true,
            alert_camp5: false,
            wapp_camp5: '',

            loading_camp_data: "",

            clients: [],
            clients_missyou: [],
            clients_birthday: [],

            userId: localStorage.getItem("resd"),
            ownerName: '',
            ownerPhone: '',
            ownerPlace: '',

            wapp_status: '',

            fileUploadState1: '',
            fileUploadState3: '',
            fileUploadState5: ''
        }

        this.inputReference1 = React.createRef()
        this.inputReference3 = React.createRef()
        this.inputReference5 = React.createRef()

        this.retry = 0
        this.daysToCutData = 2 * 365
        this.daysToBeActive = localStorage.getItem("act") //aviso geral
        this.daysToBeActive_missYou_start = localStorage.getItem("nim")
        this.daysToBeActive_missYou_end = localStorage.getItem("xam")

        this.dateToSetActive = moment.utc(new Date()).format('YYYY-MM-01T00:00:00')
        this.todayDate = moment(new Date()).format('YYYY-MM-15T00:00:00')
        this.campDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
        this.diaries = []

        this.onPage = true

        this.wappMinuteRate = 4
        this.username = localStorage.getItem("username")
        this.plan = localStorage.getItem("nalp")

    }

    componentDidMount() {
        this.getUser(this.state.userId)
        this.getPlaces(this.state.userId)
        this.getCampaigns(false)
        this.getClients()
        this.getDiaries()
        this.getWappStatus()
        this.checkSendingProgress()
    }

    componentWillUnmount() {
        this.onPage = false

    }

    getWappStatus = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch('https://waapi.app/api/v1/instances/' + instance_id + '/client/status', {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === "success") {
                    this.setState({
                        wapp_status: data.clientStatus.instanceStatus
                    })
                }
            })
            .catch(err => console.error('error:' + err))

    }

    checkSendingProgress = () => {

        if (this.onPage) {
            this.getCampaigns(true)

            setTimeout(() => {
                this.checkSendingProgress()
            }, 5000)
        } else {
            return
        }

    }

    getUser = (id) => {
        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/user/' + id, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    ownerName: data.name
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getPlaces = (userId) => {
        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/places/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    ownerPhone: data.phone,
                    ownerPlace: data.name + ' - ' + data.city_state
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getDiaries = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = this.state.userId

        fetch(BASE_URL + '/diary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    this.diaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = this.state.userId

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    clients: data,
                    clients_missyou: data.filter(client => moment.utc(new Date(this.dateToSetActive)).diff(moment.utc(new Date(client.last_date)), 'days') > this.daysToBeActive_missYou_start
                        && moment.utc(new Date(this.dateToSetActive)).diff(moment.utc(new Date(client.last_date)), 'days') < (this.daysToBeActive_missYou_end)
                        && client.camp3 !== moment(new Date(this.todayDate)).subtract(1, 'month').format('MMMM/YYYY')
                        && client.hasWhatsapp
                    ),
                    clients_birthday: data.filter(client => ((moment.utc(new Date(client.birthday)).format('MMMM') === moment(new Date(this.todayDate)).format('MMMM'))
                        && moment.utc(new Date(this.dateToSetActive)).diff(moment.utc(new Date(client.last_date)), 'days') < this.daysToCutData)
                        && client.hasWhatsapp
                    )
                })
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    getCampaigns = (onlySendingProgress) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = this.state.userId

        fetch(BASE_URL + '/campaign/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                if (!onlySendingProgress) {
                    this.setState({
                        campaigns: data,
                        today: data,
                        msg_camp1: data.msg_camp1,
                        msg_camp3: data.msg_camp3,
                        msg_camp5: typeof data.msg_camp5 !== 'undefined' ? data.msg_camp5 : '',

                        img_camp1: data.img_camp1,
                        img_camp3: data.img_camp3,
                        img_camp5: typeof data.img_camp5 !== 'undefined' ? data.img_camp5 : '',

                        status_camp1: data.status_camp1,
                        status_camp3: data.status_camp3,
                        status_camp5: typeof data.status_camp5 !== 'undefined' ? data.status_camp5 : '',

                        loading_camp_data: data.userId
                    })
                } else {
                    this.setState({
                        status_camp1: data.status_camp1,
                        status_camp3: data.status_camp3,
                        status_camp5: typeof data.status_camp5 !== 'undefined' ? data.status_camp5 : ''
                    })
                }
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    deleteCampaigns = (id) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, { method: "DELETE" })
            .then(res => {
                if (res.ok) {
                    this.getCampaigns()
                }
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    createCampaigns = (campaigns) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    this.getCampaigns()
                } else {
                    alert('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    updateCampaigns = (campaigns, id, camp) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    this.getCampaigns()

                    if (camp === 'camp1') {
                        this.setState({ alert_camp1: true })
                    }
                    if (camp === 'camp3') {
                        this.setState({ alert_camp3: true })
                    }
                    if (camp === 'camp5') {
                        this.setState({ alert_camp5: true })
                    }

                } else {
                    alert('Não foi possível atualizar a campanha!')
                    console.log(res)
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    updateMessage1 = (e) => {
        this.setState({
            msg_camp1: e.target.value
        })
    }
    updateMessage3 = (e) => {
        this.setState({
            msg_camp3: e.target.value
        })
    }
    updateMessage5 = (e) => {
        this.setState({
            msg_camp5: e.target.value
        })
    }

    deleteImg = (camp) => {
        if (camp === 'camp1') {
            this.setState({ img_camp1: '' })
        }
        if (camp === 'camp3') {
            this.setState({ img_camp3: '' })
        }
        if (camp === 'camp5') {
            this.setState({ img_camp5: '' })
        }
    }

    submit = (id, camp) => {

        this.setState({
            alert_camp1: false,
            alert_camp3: false,
            alert_camp5: false
        })

        if (camp === 'camp1') {
            this.updateCampaigns({
                img_camp1: this.state.img_camp1,
                msg_camp1: this.state.msg_camp1
            }, id, camp)
        }
        if (camp === 'camp3') {
            this.updateCampaigns({
                img_camp3: this.state.img_camp3,
                msg_camp3: this.state.msg_camp3
            }, id, camp)
        }
        if (camp === 'camp5') {
            this.updateCampaigns({
                img_camp5: this.state.img_camp5,
                msg_camp5: this.state.msg_camp5
            }, id, camp)
        }

    }

    submitWapp = async (camp) => {

        if (this.state.wapp_status === 'ready') {
            this.setState({
                wapp_camp1: '',
                wapp_camp3: '',
                wapp_camp5: ''
            })

            if (camp === 'camp1') {
                this.sendWhatsappCampaign(this.state.clients_birthday, camp)
            }
            if (camp === 'camp3') {
                this.sendWhatsappCampaign(this.state.clients_missyou, camp)
            }
            if (camp === 'camp5') {
                this.sendWhatsappCampaign(this.state.clients_active, camp)
            }
        } else {
            //wapp not connected
            if (camp === 'camp1') {
                this.setState({ wapp_camp1: 'error' })
            }
            if (camp === 'camp3') {
                this.setState({ wapp_camp3: 'error' })
            }
            if (camp === 'camp5') {
                this.setState({ wapp_camp5: 'error' })
            }
            await this.sleep(8000)
            window.location.href = "/configs"
        }

    }

    sendWhatsappCampaign = async (targetClients, camp) => {

        const msgToSend = []

        var image = ''
        var beginningOwnerMsg = ''
        var endingOwnerMsg = ''

        if (camp === 'camp1') {
            image = this.state.img_camp1
            beginningOwnerMsg = 'Envio da campanha: *Aniversário*\n\nTotal de ' + targetClients.length
                + ' clientes a serem enviados\n\nPrevisão de ' + (Math.abs(targetClients.length / this.wappMinuteRate) + 1).toFixed(0) + ' minutos\n\n'
                + 'Ao final do processo, você receberá uma mensagem de conclusão dos envios.'
            endingOwnerMsg = 'Envio da campanha *Aniversário* concluído com sucesso!'
        }
        if (camp === 'camp3') {
            image = this.state.img_camp3
            beginningOwnerMsg = 'Envio da campanha: *Sentimos sua falta*\n\nTotal de ' + targetClients.length
                + ' clientes a serem enviados\n\nPrevisão de ' + (Math.abs(targetClients.length / this.wappMinuteRate) + 1).toFixed(0) + ' minutos\n\n'
                + 'Ao final do processo, você receberá uma mensagem de conclusão dos envios.'
            endingOwnerMsg = 'Envio da campanha *Sentimos sua falta* concluído com sucesso!'
        }
        if (camp === 'camp5') {
            image = this.state.img_camp5
            beginningOwnerMsg = 'Envio da campanha: *Saindo do padrão*\n\nTotal de ' + targetClients.length
                + ' clientes a serem enviados\n\nPrevisão de ' + (Math.abs(targetClients.length / this.wappMinuteRate) + 1).toFixed(0) + ' minutos\n\n'
                + 'Ao final do processo, você receberá uma mensagem de conclusão dos envios.'
            endingOwnerMsg = 'Envio da campanha *Saindo do padrão* concluído com sucesso!'
        }

        targetClients.map((client) => {
            const name = client.name.split(" ")[0].charAt(0).toUpperCase() + client.name.split(" ")[0].slice(1).toLowerCase()

            const phoneNumber = client.phone1

            var message = ''

            if (camp === 'camp1') {
                message = this.state.msg_camp1.replace('(NOME)', name)
            }
            if (camp === 'camp3') {
                message = this.state.msg_camp3.replace('(NOME)', name)
            }
            if (camp === 'camp5') {
                message = this.state.msg_camp5.replace('(NOME)', name)
            }

            msgToSend.push({
                message: message,
                phoneNumber: phoneNumber
            })

            return 0
        })

        const dontRemoveNine = (Number(localStorage.getItem("ddd")) >= 11 && Number(localStorage.getItem("ddd")) <= 28) //sp 11-19, rj 21,22,24, es 27-28
        let ownerPhoneFinal = (this.state.ownerPhone).replace(/[-+ ()_]/g, '')
        if (!dontRemoveNine) {
            console.log('DDD para RETIRAR 9: ddd ' + localStorage.getItem("ddd"))
            if (ownerPhoneFinal.length === 13) {
                ownerPhoneFinal = ownerPhoneFinal.slice(0, 4) + ownerPhoneFinal.slice(5, 13)
            }
        }

        const ownerPlace = this.state.ownerPlace
        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")


        //control BIWAPP received msg - START
        msgToSend.unshift({
            message: beginningOwnerMsg,
            phoneNumber: '5511939501767'
        })
        //adding owner awareness msg at beginning of sending wapp msgs
        msgToSend.unshift({
            message: beginningOwnerMsg,
            phoneNumber: ownerPhoneFinal,
            place: ownerPlace,

            instance_id: instance_id,
            token: token,
            campaign_id: this.state.campaigns._id,
            campaign: camp,
            image: image
        })
        //control BIWAPP received msg - END
        msgToSend.push({
            message: endingOwnerMsg,
            phoneNumber: '5511939501767'
        })
        //adding owner awareness msg at the end of sending wapp msgs
        msgToSend.push({
            message: endingOwnerMsg,
            phoneNumber: ownerPhoneFinal
        })

        this.retry = 0

        if (process.env.REACT_APP_MODE === "dev") {
            console.log("Dev MODE - NOT sending real WhatsApp")
        } else {
            console.log("Prod MODE - SENDING real WhatsApp")
            this.sendWappMessages(msgToSend)
        }

        this.updateDiary(camp)

        if (camp === 'camp1') {
            this.setState({
                wapp_camp1: 'sent',
                btn_camp1: false
            })
        }
        if (camp === 'camp3') {
            this.setState({
                wapp_camp3: 'sent',
                btn_camp3: false
            })
        }
        if (camp === 'camp5') {
            this.setState({
                wapp_camp5: 'sent',
                btn_camp5: false
            })
        }

    }

    updateDiary = (camp) => {

        const diariesSorted = this.diaries.sort(function (a, b) {
            var c = new Date(a.date_camp1);
            var d = new Date(b.date_camp1);
            return d - c;
        })

        const monthToImport = this.getMonthToImportDiary(diariesSorted, camp)

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            if (camp === 'camp1') {
                const diaryVectorPATCH = {
                    date_camp1: this.campDate,
                    total_camp1_sent: this.state.clients_birthday.length,
                    total_camp1_goal: 0
                }
                this.patchDiary(diaryVectorPATCH, monthToImport._id)

                const clientsToUpdateCamp1 = this.clientsToUpdate(this.state.clients_birthday, camp)
                this.updateClient(clientsToUpdateCamp1)
            }
            if (camp === 'camp3') {
                const diaryVectorPATCH = {
                    date_camp3: this.campDate,
                    total_camp3_sent: this.state.clients_missyou.length,
                    total_camp3_goal: 0
                }
                this.patchDiary(diaryVectorPATCH, monthToImport._id)

                const clientsToUpdateCamp3 = this.clientsToUpdate(this.state.clients_missyou, camp)
                this.updateClient(clientsToUpdateCamp3)
            }
            if (camp === 'camp5') {
                const diaryVectorPATCH = {
                    date_camp5: this.campDate,
                    total_camp5_sent: this.state.clients_active.length,
                    total_camp5_goal: 0
                }
                this.patchDiary(diaryVectorPATCH, monthToImport._id)

                const clientsToUpdateCamp4 = this.clientsToUpdate(this.state.clients_active, camp)
                this.updateClient(clientsToUpdateCamp4)
            }
        } else {
            // deu pau - nao criou 1 diary quando importou o mês correto, checar!
        }
    }

    clientsToUpdate = (data, camp) => {

        const clientsToUpdate = []

        data.map((client) => {

            const objIndex = client.camp_history.findIndex(obj => moment(new Date(obj.refDate)).format('MMMM/YYYY') === moment(new Date(this.todayDate)).format('MMMM/YYYY'))

            if (objIndex < 0) {
                //create obj
                const camp_history = {
                    refDate: moment.utc(new Date(this.todayDate)).format('YYYY-MM-15T00:00:00'),
                    camp1: (camp === 'camp1' ? true : false),
                    camp1_date: (camp === 'camp1' ? this.campDate : ""),
                    camp3: (camp === 'camp3' ? true : false),
                    camp3_date: (camp === 'camp3' ? this.campDate : ""),
                    camp5: (camp === 'camp5' ? true : false),
                    camp5_date: (camp === 'camp5' ? this.campDate : "")
                }
                //push into already existing one
                client.camp_history.push(camp_history)
            } else {
                //update obj
                if (camp === 'camp1') {
                    client.camp_history[objIndex].camp1 = true
                    client.camp_history[objIndex].camp1_date = this.campDate
                }
                if (camp === 'camp3') {
                    client.camp_history[objIndex].camp3 = true
                    client.camp_history[objIndex].camp3_date = this.campDate
                }
                if (camp === 'camp5') {
                    client.camp_history[objIndex].camp5 = true
                    client.camp_history[objIndex].camp5_date = this.campDate
                }
            }

            if (camp === 'camp1') {
                clientsToUpdate.push({
                    camp1: moment.utc(new Date(this.todayDate)).format('MMMM/YYYY'),
                    _id: client._id,
                    camp_history: client.camp_history
                })
            }
            if (camp === 'camp3') {
                clientsToUpdate.push({
                    camp3: moment.utc(new Date(this.todayDate)).format('MMMM/YYYY'),
                    _id: client._id,
                    camp_history: client.camp_history
                })
            }
            if (camp === 'camp5') {
                clientsToUpdate.push({
                    camp5: moment.utc(new Date(this.todayDate)).format('MMMM/YYYY'),
                    _id: client._id,
                    camp_history: client.camp_history
                })
            }
            return 0
        })
        return clientsToUpdate
    }

    updateClient = (data) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    patchDiary = (data, id) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getMonthToImportDiary = (data, camp) => {

        if (camp === 'camp2' || camp === 'camp3') {
            // const diaryMonthAndYear = moment(new Date()).subtract(1, 'months').format('MMMM/YYYY')
            const diaryMonthAndYear = moment(new Date(this.todayDate)).format('MMMM/YYYY')
            const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY') === diaryMonthAndYear)

            if (diaryMonthAndYear_alive.length >= 1) {
                return diaryMonthAndYear_alive[0]
            } else {
                return []
            }
        } else {
            const diaryMonthAndYear = moment(new Date(this.todayDate)).format('MMMM/YYYY')
            const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY') === diaryMonthAndYear)

            if (diaryMonthAndYear_alive.length >= 1) {
                return diaryMonthAndYear_alive[0]
            } else {
                return []
            }
        }
    }

    sendWappMessages = (wappMessages) => {

        console.log("Tentativa " + (this.retry + 1))

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/wappMsg', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(wappMessages)
        })
            .then(async res => {
                if (res.ok) {
                    return res
                } else {
                    if (this.retry <= 2) {
                        console.log('Não foi possível, tentando novamente!')
                        await this.sleep(5000)
                        this.sendWappMessages(wappMessages)

                        this.retry = this.retry + 1
                    } else {
                        console.log('Não foi possível, tentamos 3x')
                    }
                }
            })
            .catch(rejected => {
                // console.log('Catch - rejected')
                console.log(rejected)
            })
    }

    sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    fileUploadAction1 = () => this.inputReference1.current.click();

    fileUploadInputChange1 = (e) => {
        this.setState({ fileUploadState1: e.target.value })
        this.getBase64(e, 'camp1')
    }

    fileUploadAction3 = () => this.inputReference3.current.click();

    fileUploadInputChange3 = (e) => {
        this.setState({ fileUploadState3: e.target.value })
        this.getBase64(e, 'camp3')
    }

    fileUploadAction5 = () => this.inputReference5.current.click();

    fileUploadInputChange5 = (e) => {
        this.setState({ fileUploadState5: e.target.value })
        this.getBase64(e, 'camp5')
    }

    getBase64 = (e, camp) => {

        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            if (camp === 'camp1') {
                this.setState({ img_camp1: reader.result })
            }
            if (camp === 'camp3') {
                this.setState({ img_camp3: reader.result })
            }
            if (camp === 'camp5') {
                this.setState({ img_camp5: reader.result })
            }
        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
        }
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.loading_camp_data !== '' && this.state.wapp_status !== ''
                        ?
                        <>
                            <h3>Campanhas</h3>

                            {this.state.alert_camp1
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={1} variant='primary' className="text-center">
                                            A campanha de <b>aniversário</b> foi salva com sucesso!
                                        </Alert>
                                    </div>
                                </Message>
                                : ''}
                            {this.state.wapp_camp1 === 'sent'
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={1} variant='success' className="text-center">
                                            <Alert.Heading>Enviando campanha pelo WhatsApp</Alert.Heading>
                                            <br></br>
                                            A campanha de <b>aniversário</b> está sendo envida com sucesso!
                                            <hr />
                                            Tempo estimado: <b>{Math.abs((this.state.clients_birthday.length / this.wappMinuteRate) + 1).toFixed(0)} minuto(s)</b>
                                        </Alert>
                                    </div>
                                </Message>
                                : this.state.wapp_camp1 === 'error'
                                    ? <Message>
                                        <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                            <Alert key={4} variant='danger' className="text-center">
                                                <Alert.Heading>WhatsApp não conectado</Alert.Heading>
                                                <br></br>
                                                Conecte seu número de WhatsApp na plataforma antes de usar o envio de campanhas.
                                                <hr />
                                                Você será redirecionado para a página de configurações
                                            </Alert>
                                        </div>
                                    </Message>
                                    : ''}

                            {this.state.alert_camp3
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={3} variant='primary' className="text-center">
                                            A campanha de <b>sentimos sua falta</b> foi salva com sucesso!
                                        </Alert>
                                    </div>
                                </Message>
                                : ''}
                            {this.state.wapp_camp3 === 'sent'
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={3} variant='success' className="text-center">
                                            <Alert.Heading>Enviando campanha pelo WhatsApp</Alert.Heading>
                                            <br></br>
                                            A campanha <b>sentimos sua falta</b> está sendo envida com sucesso!
                                            <hr />
                                            Tempo estimado: <b>{Math.abs(((this.state.clients_missyou.length) / this.wappMinuteRate) + 1).toFixed(0)} minuto(s)</b>
                                        </Alert>
                                    </div>
                                </Message>
                                : this.state.wapp_camp3 === 'error'
                                    ? <Message>
                                        <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                            <Alert key={4} variant='danger' className="text-center">
                                                <Alert.Heading>WhatsApp não conectado</Alert.Heading>
                                                <br></br>
                                                Conecte seu número de WhatsApp na plataforma antes de usar o envio de campanhas.
                                                <hr />
                                                Você será redirecionado para a página de configurações
                                            </Alert>
                                        </div>
                                    </Message>
                                    : ''}
                                    
                            {this.state.alert_camp5
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={4} variant='primary' className="text-center">
                                            A campanha de <b>saindo do padrão</b> foi salva com sucesso!
                                        </Alert>
                                    </div>
                                </Message>
                                : ''}
                            {this.state.wapp_camp5 === 'sent'
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={4} variant='success' className="text-center">
                                            <Alert.Heading>Enviando campanha pelo WhatsApp</Alert.Heading>
                                            <br></br>
                                            A campanha <b>saindo do padrão</b> está sendo envida com sucesso!
                                            <hr />
                                            Tempo estimado: <b>{Math.abs((this.state.clients_active.length / this.wappMinuteRate) + 1).toFixed(0)} minuto(s)</b>
                                        </Alert>
                                    </div>
                                </Message>
                                : this.state.wapp_camp5 === 'error'
                                    ? <Message>
                                        <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                            <Alert key={4} variant='danger' className="text-center">
                                                <Alert.Heading>WhatsApp não conectado</Alert.Heading>
                                                <br></br>
                                                Conecte seu número de WhatsApp na plataforma antes de usar o envio de campanhas.
                                                <hr />
                                                Você será redirecionado para a página de configurações
                                            </Alert>
                                        </div>
                                    </Message>
                                    : ''}

                            <CardGroup>
                                <Card border="" className="text-center" >
                                    <Card.Header>
                                        <b>Aniversário</b>
                                    </Card.Header>
                                    {this.state.img_camp1 !== ''
                                        ? <Card.Img variant="top" src={this.state.img_camp1}
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />
                                        : <Card.Img variant="top" src='./images/sem_foto.png'
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />}
                                    <Card.Body>
                                        <div>
                                            <input type="file" hidden ref={this.inputReference1} onChange={this.fileUploadInputChange1} />
                                            <Button variant="dark" size="sm" onClick={this.fileUploadAction1}>
                                                {this.state.img_camp1 !== '' ? 'Alterar' : 'Adicionar foto'}
                                            </Button> {' '}
                                            {this.state.img_camp1 !== ''
                                                ? <Button variant="dark" size="sm" onClick={() => this.deleteImg('camp1')}>
                                                    Excluir foto</Button>
                                                : ''}
                                        </div>
                                        <br></br>
                                        <Form>
                                            <Form.Control as="textarea" rows={8} value={this.state.msg_camp1}
                                                placeholder="Insira uma mensagem" onChange={this.updateMessage1} />
                                        </Form>
                                        <br></br>
                                        Total de aniversariantes sugerido: <b>{this.state.clients_birthday.length}</b>
                                        <br></br>
                                        <span className="CardFooter" style={{ color: 'gray' }}>Ref. {moment(new Date()).format('MMMM/YYYY')}</span>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant="dark" size="sm" onClick={() => this.submit(this.state.campaigns._id, 'camp1')}>
                                            Salvar
                                        </Button>
                                        {' '}
                                        {this.username === "virginia"
                                            ? this.state.status_camp1 === 0 && this.state.btn_camp1 && this.state.clients_birthday.length !== 0
                                                ? <Button className="buttonBIWAPP" size="sm" onClick={() => this.submitWapp('camp1')}>
                                                    Enviar WhatsApp
                                                </Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(this.state.status_camp1).toFixed(1)}% enviado</Button>
                                            : this.state.status_camp1 === 0
                                                ? <Button className="buttonBIWAPP" size="sm" disabled>Agendado</Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(this.state.status_camp1).toFixed(1)}% enviado</Button>}
                                    </Card.Footer>
                                </Card>

                                <Card border="" className="text-center">
                                    <Card.Header><b>Sentimos sua falta</b></Card.Header>
                                    {this.state.img_camp3 !== ''
                                        ? <Card.Img variant="top" src={this.state.img_camp3}
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />
                                        : <Card.Img variant="top" src='./images/sem_foto.png'
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />}
                                    <Card.Body>
                                        <div>
                                            <input type="file" hidden ref={this.inputReference3} onChange={this.fileUploadInputChange3} />
                                            <Button variant="dark" size="sm" onClick={this.fileUploadAction3}>
                                                {this.state.img_camp3 !== '' ? 'Alterar' : 'Adicionar foto'}
                                            </Button> {' '}
                                            {this.state.img_camp3 !== ''
                                                ? <Button variant="dark" size="sm" onClick={() => this.deleteImg('camp3')}>
                                                    Excluir foto</Button>
                                                : ''}
                                        </div>
                                        <br></br>
                                        <Form>
                                            <Form.Control as="textarea" rows={8} value={this.state.msg_camp3}
                                                placeholder="Insira uma mensagem" onChange={this.updateMessage3} />
                                        </Form>
                                        <br></br>
                                        Total de clientes a resgatar: <b>{this.state.clients_missyou.length}</b>
                                        <br></br>
                                        <span className="CardFooter" style={{ color: 'gray' }}>Última visita entre {this.daysToBeActive_missYou_start / 30} e {this.daysToBeActive_missYou_end / 30} meses atrás</span>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant="dark" size="sm" onClick={() => this.submit(this.state.campaigns._id, 'camp3')}>
                                            Salvar
                                        </Button>
                                        {' '}
                                        {this.username === "virginia"
                                            ? this.state.status_camp3 === 0 && this.state.btn_camp3 && this.state.clients_missyou.length !== 0
                                                ? <Button className="buttonBIWAPP" size="sm" onClick={() => this.submitWapp('camp3')}>
                                                    Enviar WhatsApp
                                                </Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(this.state.status_camp3).toFixed(1)}% enviado</Button>
                                            : this.state.status_camp3 === 0
                                                ? <Button className="buttonBIWAPP" size="sm" disabled>Agendado</Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(this.state.status_camp3).toFixed(1)}% enviado</Button>}
                                    </Card.Footer>
                                </Card>

                                <Card border="" className="text-center">
                                    <Card.Header><b>Saindo do padrão (em breve)</b></Card.Header>
                                    {this.state.img_camp5 !== ''
                                        ? <Card.Img variant="top" src={this.state.img_camp5}
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />
                                        : <Card.Img variant="top" src='./images/sem_foto.png'
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />}
                                    <Card.Body>
                                        <div>
                                            <input type="file" hidden ref={this.inputReference5} onChange={this.fileUploadInputChange5} />
                                            <Button variant="dark" size="sm" onClick={this.fileUploadAction5}>
                                                {this.state.img_camp5 !== '' ? 'Alterar' : 'Adicionar foto'}
                                            </Button> {' '}
                                            {this.state.img_camp5 !== ''
                                                ? <Button variant="dark" size="sm" onClick={() => this.deleteImg('camp5')}>
                                                    Excluir foto</Button>
                                                : ''}
                                        </div>
                                        <br></br>
                                        <Form>
                                            <Form.Control as="textarea" rows={8} value={this.state.msg_camp5}
                                                placeholder="Insira uma mensagem" onChange={this.updateMessage5} />
                                        </Form>
                                        <br></br>
                                        Total de novos clientes: <b>0</b>
                                        <br></br>
                                        <span className="CardFooter" style={{ color: 'gray' }}>Ref. {moment(new Date()).subtract(1, 'months').format('MMMM/YYYY')}</span>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant="dark" size="sm" onClick={() => this.submit(this.state.campaigns._id, 'camp5')}>
                                            Salvar
                                        </Button>
                                        {' '}
                                        {this.username === "virginia"
                                            ? this.state.status_camp5 === 0 && this.state.btn_camp5
                                                ? <Button className="buttonBIWAPP" size="sm" onClick={() => this.submitWapp('camp5')}>
                                                    Enviar WhatsApp
                                                </Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{typeof this.state.status_camp5 !== 'undefined' && this.state.status_camp5 !== '' ? (this.state.status_camp5).toFixed(1) : 0}% enviado</Button>
                                            : this.state.status_camp5 === 0
                                                ? <Button className="buttonBIWAPP" size="sm" disabled>Agendado</Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(typeof this.state.status_camp5 !== 'undefined' && this.state.status_camp5 !== '') ? (this.state.status_camp5).toFixed(1) : 0}% enviado</Button>}
                                    </Card.Footer>
                                </Card>


                            </CardGroup>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Campaigns