import { React, useRef } from 'react'
import * as XLSX from 'xlsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Button } from "react-bootstrap"

function FileInputClientsDB() {

    const hiddenFileInput = useRef(null)

    const handleClick = event => {
        hiddenFileInput.current.click()
    }

    const handleChange = event => {
        handleFileUpload(event)
    }

    const userId = localStorage.getItem("resd")
    const user_pdv = localStorage.getItem("pdv")

    const importDateDiaries = []
    const monthExpectedToImportLong = []

    const clientsAlive = []

    let clientsGotten = false


    getClients()

    getImportDateDiaries()

    const handleFileUpload = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = async (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = XLSX.utils.sheet_to_json(sheet)

            const clientsFromSheet = await integrationSelector(sheetData)

            console.log(clientsFromSheet)

            if (clientsFromSheet.length > 0) {

                const clientsToUpdate = getClientsToUpdate(clientsFromSheet, clientsAlive)
                if (clientsToUpdate.length > 0) {
                    await updateClient(clientsToUpdate)
                }

                await createImportDateDiary(clientsToUpdate, clientsFromSheet.length)

                alert("Arquivo importado com sucesso!")
                window.location.reload()

            }

        }

        reader.readAsBinaryString(file);

    }

    async function integrationSelector(sheetData) {

        if (user_pdv === "allegro") {
            return await integration5asec(sheetData)
        }
        if (user_pdv === "elav") {
            return await integrationElav(sheetData)
        }
        if (user_pdv === "swl") {
            return await integrationSwl(sheetData)
        }

    }

    async function integration5asec(sheetData) {

        // NAO TEM
        setClientsDB_5asec()

    }

    async function integrationElav(sheetData) {

        const planFieldNames = Object.getOwnPropertyNames(sheetData[1])

        let isClientsDB = false
        let isExpectedMonth = false
        let fileMonthAndYear = ''
        const monthExpectedToImport = getMonthExpectedToImport()

        for (let index = 0; index < planFieldNames.length; index++) {
            const element = planFieldNames[index]
            if (element.includes("PESSOAS")) {
                isClientsDB = true

                const fieldItems = sheetData[1].__EMPTY_28.split(' ')
                const fileEndDate = fieldItems[0]
                fileMonthAndYear = fileEndDate.slice(3)

                if (fileMonthAndYear === monthExpectedToImport) {
                    isExpectedMonth = true
                }
            }
        }

        if (isClientsDB) {

            if (isExpectedMonth) {

                while (!clientsGotten) {
                    // console.log("wait clients request")
                    await sleep(1000)
                }

                const clients = await setClientsDB_elav(sheetData)

                const clearedClients = clients.filter(client => client.phone1 !== '' && client.phone1 !== 'CELULAR')

                return clearedClients

            } else {
                alert('\nImportação do período incorreto!'
                    + '\n\nVocê está importando o mês de ' + fileMonthAndYear
                    + '\n\nPrecisamos do mês de ' + monthExpectedToImport)
            }

        } else {
            alert("\nO arquivo escolhido não é o RELATÓRIO DE PESSOAS compatível")
        }

        return []

    }

    async function integrationSwl(sheetData) {

        // consle.log(sheetData)

        const planFieldNames = Object.getOwnPropertyNames(sheetData[1])

        let isClientsDB = false
        let isExpectedMonth = false
        let fileMonthAndYear = ''
        const monthExpectedToImport = getMonthExpectedToImport()

        for (let index = 0; index < planFieldNames.length; index++) {
            const element = planFieldNames[index]
            if (element.includes("Clientes")) {
                isClientsDB = true

                const fieldItems = sheetData[1].__EMPTY_28.split(' ')
                const fileEndDate = fieldItems[0]
                fileMonthAndYear = fileEndDate.slice(3)

                if (fileMonthAndYear === monthExpectedToImport) {
                    isExpectedMonth = true
                }
            }
        }

        if (isClientsDB) {

            if (isExpectedMonth) {

                while (!clientsGotten) {
                    // console.log("wait clients request")
                    await sleep(1000)
                }

                const clients = await setClientsDB_swl(sheetData)

                const clearedClients = clients.filter(client => client.phone1 !== '' && client.phone1 !== 'CEL')

                return clearedClients

            } else {
                alert('\nImportação do período incorreto!'
                    + '\n\nVocê está importando o mês de ' + fileMonthAndYear
                    + '\n\nPrecisamos do mês de ' + monthExpectedToImport)
            }

        } else {
            alert("\nO arquivo escolhido não é o RELATÓRIO DE RANKING DE CLIENTES compatível")
        }

        return []

    }

    async function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    function getMonthExpectedToImport() {

        var monthExpected = 'Not Found'

        importDateDiaries.map((importDiary) => {
            if (monthExpected === 'Not Found') {
                monthExpected = moment.utc(new Date()).format('MM/YYYY')
                monthExpectedToImportLong.push(
                    {
                        month: moment.utc(new Date()).format('MMMM/YYYY'),
                        date: moment.utc(new Date()).format('YYYY-MM-15T00:00:00')
                    }
                )
            }
            return 0
        })

        return monthExpected

    }

    async function setClientsDB_5asec(data) {

        //NAO TEM

    }

    async function setClientsDB_elav(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: (typeof client.__EMPTY != 'undefined'
                    ? (client.__EMPTY).split('-')[0]
                    : ''),
                name: (typeof client.__EMPTY != 'undefined'
                    ? (client.__EMPTY).split('-')[1]
                    : ''),
                phone1: (typeof client.__EMPTY_18 != 'undefined' ? client.__EMPTY_18 : ''),
                phone2: (typeof client.__EMPTY_15 != 'undefined' ? client.__EMPTY_15 : '')
            })
        )
        return clients

    }

    async function setClientsDB_swl(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: (typeof client.__EMPTY != 'undefined'
                    ? (client.__EMPTY).split('-')[0]
                    : ''),
                name: (typeof client.__EMPTY != 'undefined'
                    ? (client.__EMPTY).split('-')[1]
                    : ''),
                phone1: (typeof client.__EMPTY_18 != 'undefined' ? client.__EMPTY_18 : ''),
                phone2: (typeof client.__EMPTY_15 != 'undefined' ? client.__EMPTY_15 : '')
            })
        )
        return clients

    }

    async function updateClient(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function getImportDateDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return c - d;
                    })
                    .map((diary) => {
                        importDateDiaries.push(diary)
                        return 0
                    })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getClients() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((client) => {
                    clientsAlive.push(client)
                    return 0
                })
                clientsGotten = true
                // console.log("acabou o request")
                // console.log(data.length)
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function getClientsToUpdate(data, allClients) {

        const clientsToUpdate = []

        data.filter(function (elem) {

            const cli = allClients.find(({ name, unique_identifier }) => (elem.name).toLowerCase() === name.toLowerCase()
                && elem.unique_identifier === unique_identifier
            )

            if (typeof cli !== 'undefined') {

                clientsToUpdate.push({
                    name: cli.name,
                    unique_identifier: cli.unique_identifier,
                    phone1: (elem.phone1).replace(/[- ()_]/g, ''),
                    phone2: (elem.phone2).replace(/[- ()_]/g, ''),
                    _id: cli._id
                })
                return cli
            } else {
                return undefined
            }
        })

        if (clientsToUpdate.length >= 1) {
            return clientsToUpdate
        } else {
            return []
        }
    }

    function sortByDateDiaries(data) {
        const dataSortedByDate = data.sort(function (a, b) {
            var c = new Date(a.date);
            var d = new Date(b.date);
            return d - c;
        })
        return dataSortedByDate
    }

    function getMonthToImport(diaries) {

        const dataToImportMonthAndYear = monthExpectedToImportLong[0].month
        const dataToImportMonthAndYear_alive = diaries.filter(bidiary => moment.utc(new Date(bidiary.date)).format('MMMM/YYYY') === dataToImportMonthAndYear)

        if (dataToImportMonthAndYear_alive.length >= 1) {
            return dataToImportMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    function patchImportDateDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function createImportDateDiary(data, totalDB) {

    
        const monthToImport = getMonthToImport(sortByDateDiaries(importDateDiaries))

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const bestClientsVectorPATCH = {
                date: monthToImport.date,

                clientsDB: {
                    total: totalDB,
                    actives: data.length,
                    importDate: new Date().toUTCString()
                }
            }

            patchImportDateDiary(bestClientsVectorPATCH, monthToImport._id)

        }

    }

    return (
        <>

            <Button variant="dark" size="sm" onClick={handleClick} >
                Importar
            </Button>

            <input type="file" id="formId" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />

        </>
    )
}

export default FileInputClientsDB;