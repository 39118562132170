import React from "react"
import { Text } from 'react-native'
// import { Table } from "react-bootstrap"
import { Card, Row, Col } from "react-bootstrap"
// import { MdOutlineTrendingUp, MdOutlineTrendingDown, MdOutlineTrendingFlat } from "react-icons/md"
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import Loading from "../Auxiliaries/Loading"
import moment from 'moment'
import 'moment/locale/pt-br'

import { BarPlot } from '@mui/x-charts/BarChart'
import { LineHighlightPlot, LinePlot } from '@mui/x-charts/LineChart'
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer'
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis'
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis'
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip'
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight'

import { BarChart } from '@mui/x-charts/BarChart';


class Bi extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bis: [],
            today: [],
            clients: [],
            clientsWTracking: [],
            clients_active: [],
            clients_active_ticket: 0,
            clients_repeat_ticket: 0,
            clients_new_ticket: 0,
            clients_dnr_ticket: 0,
            graphicDataMR: [],
        }

        this.graphicDataBreakdown_total = []
        this.graphicDataBreakdown_repeat = []
        this.graphicDataBreakdown_nrepeat = []
        this.graphicDataBreakdown_new = []
        this.graphicDataBreakdown_biwapp = []

        this.bis2 = []
        this.importDateDiaries = []
        this.dateToSetActive = moment.utc(new Date()).format('YYYY-MM-01T00:00:00')

        this.getClientsDone = false
        this.getBisDone = false
        this.getImportDateDiariesDone = false
    }

    componentDidMount() {
        this.getClients()
        this.getBis()
    }

    componentWillUnmount() {

    }

    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                const clients_active = data.filter(client => client.active)
                this.setState({
                    clients: data,
                    clients_active: clients_active,
                    clients_active_ticket: clients_active
                        .reduce((n, { last_ticket }) => n + last_ticket, 0),
                    clients_repeat_ticket: data
                        .filter(client => client.active && client.last_ticket > 0 && client.last_last_ticket > 0 && client.last_ticket !== client.last_last_ticket)
                        .reduce((n, { last_ticket }) => n + last_ticket, 0),
                    clients_new_ticket: data
                        .filter((client => moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === moment(new Date()).subtract(1, 'months').format('MMMM/YYYY')))
                        .reduce((n, { last_ticket }) => n + last_ticket, 0),
                    clients_dnr_ticket: data
                        .filter(client => !client.active)
                        .reduce((n, { last_active_ticket }) => n + last_active_ticket, 0),
                })

                let clientsWTracking = {
                    months: ["13", "12", "11", "10", "9", "8", "7", "6", "5", "4", "3", "2", "1"],
                    revenue: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    revenueAvg: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    lastDates: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                }

                data.map(client => {
                    const tracking = client.tracking
                        .toSorted(function (a, b) {
                            var c = new Date(a.refDate);
                            var d = new Date(b.refDate);
                            return c - d;
                        })

                    for (let index = 1; index <= 13; index++) {

                        const monthVisit = moment.utc(new Date(this.dateToSetActive)).subtract(index, "month")
                        const hasVisitInMonth = tracking
                            .filter((track) => moment.utc(new Date(track.refDate)).format('MMMM/YYYY') === monthVisit.format('MMMM/YYYY') && track.ticket !== 0)

                        if (hasVisitInMonth.length !== 0) {
                            clientsWTracking.months[index - 1] = monthVisit.format('MMM/YY')
                            clientsWTracking.revenue[index - 1] = clientsWTracking.revenue[index - 1] + hasVisitInMonth[0].ticket
                        } else {
                            clientsWTracking.months[index - 1] = monthVisit.format('MMM/YY')
                        }
                        if (moment.utc(new Date(client.last_date)).format('MMMM/YYYY') === monthVisit.format('MMMM/YYYY')) {
                            clientsWTracking.lastDates[index - 1] = clientsWTracking.lastDates[index - 1] + 1
                        }
                    }
                    return 0
                })

                for (let index = 0; index < clientsWTracking.months.length; index++) {

                    if (index <= 10) {
                        clientsWTracking.revenueAvg[index] = (clientsWTracking.revenue[index] + clientsWTracking.revenue[index + 1] + clientsWTracking.revenue[index + 2]) / 3
                    } else {
                        clientsWTracking.revenueAvg[index] = clientsWTracking.revenue[index]
                    }

                }

                const lastDates_vector = (clientsWTracking.lastDates)

                clientsWTracking.months = (clientsWTracking.months.reverse()).slice(0, 13)
                clientsWTracking.revenue = (clientsWTracking.revenue.reverse()).slice(0, 13)
                clientsWTracking.revenueAvg = (clientsWTracking.revenueAvg.reverse()).slice(0, 13)
                clientsWTracking.lastDates = (lastDates_vector.slice().reverse()).slice(0, 13)

                this.setState({
                    clients: data.toSorted((a, b) => a.last_ticket < b.last_ticket ? 1 : -1),
                    graphicDataMR: clientsWTracking
                })

            })
            .catch(rejected => {
                console.log(rejected)
            })
            .finally(data => {
                this.getClientsDone = true
            })
    }

    getBis = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")

        fetch(BASE_URL + "/biDiary/" + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    bis: data,
                    today: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return d - c;
                        })[0]
                })

                const dataSorted = data
                    .map(diary => { return diary })
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return d - c;
                    })

                const graphic_repeat = []
                const graphic_new = []
                const graphic_biwapp = []

                this.bis2 = dataSorted
                    .slice(0, 13)
                    .map(function (dataSortedOne, index) {

                        graphic_repeat.push(dataSorted[index].total_repeat)
                        graphic_new.push(dataSorted[index].total_newclient)
                        graphic_biwapp.push(dataSorted[index].total_biwapp)

                        if (typeof dataSorted[index + 1] !== 'undefined') {
                            return {
                                _id: dataSorted[index]._id,
                                date: dataSorted[index].date,
                                total_clients: dataSorted[index].total_clients,
                                total_actives: dataSorted[index].total_actives,
                                total_actives_diff: (dataSorted[index + 1].total_actives) > 0 ? (dataSorted[index].total_actives - dataSorted[index + 1].total_actives) / (dataSorted[index + 1].total_actives) : 0,
                                total_repeat: dataSorted[index].total_repeat,
                                total_repeat_diff: (dataSorted[index + 1].total_repeat) > 0 ? (dataSorted[index].total_repeat - dataSorted[index + 1].total_repeat) / (dataSorted[index + 1].total_repeat) : 0,
                                total_newclient: dataSorted[index].total_newclient,
                                total_newclient_diff: (dataSorted[index + 1].total_newclient) > 0 ? (dataSorted[index].total_newclient - dataSorted[index + 1].total_newclient) / (dataSorted[index + 1].total_newclient) : 0,
                                total_dnr: dataSorted[index].total_dnr,
                                total_dnr_diff: (dataSorted[index + 1].total_dnr) > 0 ? (dataSorted[index].total_dnr - dataSorted[index + 1].total_dnr) / (dataSorted[index + 1].total_dnr) : 0
                            }
                        } else {
                            return {
                                date: dataSorted[index].date,
                                total_clients: dataSorted[index].total_clients,
                                total_actives: dataSorted[index].total_actives,
                                total_actives_diff: 0,
                                total_repeat: dataSorted[index].total_repeat,
                                total_repeat_diff: 0,
                                total_newclient: dataSorted[index].total_newclient,
                                total_newclient_diff: 0,
                                total_dnr: dataSorted[index].total_dnr,
                                total_dnr_diff: 0
                            }
                        }
                    })

                this.graphicDataBreakdown_repeat = graphic_repeat.reverse()
                this.graphicDataBreakdown_new = graphic_new.reverse()
                this.graphicDataBreakdown_biwapp = graphic_biwapp.reverse()

            })
            .catch(rejected => {
                console.log(rejected)
            })
            .finally(data => {
                this.getBisDone = true
                this.getImportDateDiaries()
            })

    }

    getImportDateDiaries = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = localStorage.getItem("resd")

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    importDateDiaries: data
                        .map(diary => { return diary })
                        .sort(function (a, b) {
                            var c = new Date(a.date);
                            var d = new Date(b.date);
                            return d - c;
                        })
                        .filter(diary => moment.utc(new Date(diary.date)).format('MMMM/YYYY') !== moment(new Date()).format('MMMM/YYYY'))
                })

                const dataSorted = data
                    .map(diary => { return diary })
                    .sort(function (a, b) {
                        var c = new Date(a.date);
                        var d = new Date(b.date);
                        return d - c;
                    })
                    .filter(diary => moment.utc(new Date(diary.date)).format('MMMM/YYYY') !== moment(new Date()).format('MMMM/YYYY'))

                const graphic_total = []
                const graphic_nrepeat = []
                const graphic_repeat_bk = this.graphicDataBreakdown_repeat.reverse()
                const graphic_new_bk = this.graphicDataBreakdown_new.reverse()
                const graphic_biwapp_bk = this.graphicDataBreakdown_biwapp.reverse()

                this.importDateDiaries = dataSorted
                    .slice(0, 13)
                    .map(function (dataSortedOne, index) {

                        graphic_total.push(dataSorted[index].bestClients.total)
                        graphic_nrepeat.push(dataSorted[index].bestClients.total -
                            (graphic_repeat_bk[index] + graphic_new_bk[index] + graphic_biwapp_bk[index]))

                        if (typeof dataSorted[index + 1] !== 'undefined') {
                            return {
                                _id: dataSorted[index]._id,
                                date: dataSorted[index].date,
                                visitors: dataSorted[index].bestClients.total,
                                visitors_diff: (dataSorted[index + 1].bestClients.total) > 0 ? (dataSorted[index].bestClients.total - dataSorted[index + 1].bestClients.total) / (dataSorted[index + 1].bestClients.total) : 0
                            }
                        } else {
                            return {
                                _id: dataSorted[index]._id,
                                date: dataSorted[index].date,
                                visitors: dataSorted[index].bestClients.total,
                                visitors_diff: 0
                            }
                        }
                    })

                this.graphicDataBreakdown_nrepeat = graphic_nrepeat
                this.graphicDataBreakdown_total = graphic_total

                this.graphicDataBreakdown_total.reverse()
                this.graphicDataBreakdown_nrepeat.reverse()

                this.graphicDataBreakdown_repeat.reverse()
                this.graphicDataBreakdown_new.reverse()
                this.graphicDataBreakdown_biwapp.reverse()

            })
            .catch(rejected => {
                console.log(rejected)
            })
            .finally(data => {
                this.getImportDateDiariesDone = true
            })
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.getClientsDone && this.getBisDone && this.getImportDateDiariesDone
                        ?
                        <>
                            <h3>Business Intelligence</h3>

                            <Row xs={1} md={5} className="g-4">
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes pagantes</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Visita com gasto no mês passado
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients_active.length > 0 ? (this.importDateDiaries[0] ? Math.abs(((this.importDateDiaries[0].visitors) / (this.state.clients_active.length) * 100).toFixed(0)) : 0) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients_active.length > 0 ? (this.importDateDiaries[0] ? Math.abs(((this.importDateDiaries[0].visitors) / (this.state.clients_active.length) * 100).toFixed(0)) : 0) : 0} %</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.importDateDiaries[0] ? this.importDateDiaries[0].visitors : '0'}</b> de {this.state.clients_active.length} na base ativa <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.importDateDiaries[0] ? (this.state.clients_active_ticket / this.importDateDiaries[0].visitors).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0)}</b>
                                                <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_active_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes recorrêntes</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Visitou nos 2 últimos meses seguidos
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients_active.length > 0 ? Math.abs((this.state.today.total_repeat / this.state.clients_active.length * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients_active.length > 0 ? Math.abs((this.state.today.total_repeat / this.state.clients_active.length * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.today.total_repeat}</b> de {this.state.clients_active.length} na base ativa <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.state.clients_repeat_ticket / this.state.today.total_repeat).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_repeat_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes novos</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Primeira visita foi no mês passado
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients_active.length > 0 ? Math.abs(((this.state.today.total_newclient) / (this.state.clients_active.length) * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients_active.length > 0 ? Math.abs(((this.state.today.total_newclient) / (this.state.clients_active.length) * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.today.total_newclient}</b> de {this.state.clients_active.length} na base ativa <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.state.clients_new_ticket / this.state.today.total_newclient).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_new_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes ativos</Card.Title><Text style={{ color: 'gray' }}>
                                                Última visita a menos de 90 dias
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients.length > 0 ? Math.abs(((this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#24d366',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients.length > 0 ? Math.abs(((this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.clients_active.length}</b> de {this.state.clients.length} na base total <br></br>
                                                <br></br>
                                                <b>Retorno/cliente | R$ {(this.state.clients_active_ticket / this.state.today.total_actives).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Retorno total | R$ {(this.state.clients_active_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <Card.Title className="BiCard-title">Clientes que não retornaram</Card.Title>
                                            <Text style={{ color: 'gray' }}>
                                                Última visita a mais de 90 dias
                                            </Text>
                                            <br></br>
                                            <br></br>
                                            <div style={{ width: 100, height: 100, margin: '0 auto' }}>
                                                <CircularProgressbarWithChildren value={this.state.clients.length > 0 ? Math.abs(((this.state.clients.length - this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) : 0}
                                                    styles={{
                                                        path: {
                                                            // Path color
                                                            stroke: '#ed4545',
                                                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                            strokeLinecap: 'round'
                                                        },
                                                        text: {
                                                            // Text color
                                                            fill: '#000',
                                                            // Text size
                                                            fontSize: '40px'
                                                        }
                                                    }}>
                                                    {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                    <div style={{ fontSize: 18, marginTop: -5 }}>
                                                        <strong>{this.state.clients.length > 0 ? Math.abs(((this.state.clients.length - this.state.clients_active.length) / (this.state.clients.length) * 100).toFixed(0)) + '%' : 0}</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                            <Card.Text className="BiCard-text">
                                                <br></br>
                                                <b>{this.state.clients.length - this.state.clients_active.length}</b> de {this.state.clients.length} na base total <br></br>
                                                <br></br>
                                                <b>Perda/cliente | R$ {(this.state.clients_dnr_ticket / this.state.today.total_dnr).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b> <br></br>
                                                <span className="CardFooter" style={{ color: 'gray' }}>Perda total | R$ {(this.state.clients_dnr_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {/* <div>
                                <br></br>
                            </div>

                            <h3>Histórico de B.I.</h3>
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Clientes pagantes</th><th></th>
                                        <th>Clientes recorrentes</th><th></th>
                                        <th>Clientes novos</th><th></th>
                                        <th>Clientes ativos</th><th></th>
                                        <th>Clientes que não retornaram</th><th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.bis2
                                            .map((bi, index) =>
                                                <tr key={bi.date}>
                                                    <td>{moment.utc(new Date(bi.date)).format('MMMM/YYYY')}</td>
                                                    <td>{this.importDateDiaries[index].visitors} importados</td><td>{this.importDateDiaries[index].visitors_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : this.importDateDiaries[index].visitors_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(this.importDateDiaries[index].visitors_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_repeat} importados</td><td>{bi.total_repeat_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_repeat_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_repeat_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_newclient} importados</td><td>{bi.total_newclient_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_newclient_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_newclient_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_actives} acumulados</td><td>{bi.total_actives_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_actives_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_actives_diff * 100).toFixed(0)}%</td>
                                                    <td>{bi.total_dnr} importados</td><td>{bi.total_dnr_diff < 0 ? <MdOutlineTrendingDown color="#ed4545" /> : bi.total_dnr_diff > 0 ? <MdOutlineTrendingUp color="#24d366" /> : <MdOutlineTrendingFlat color="orange" />}{' '}{Math.abs(bi.total_dnr_diff * 100).toFixed(0)}%</td>
                                                </tr>
                                            )
                                    }
                                </tbody>
                            </Table> */}

                            <div>
                                <br></br>
                            </div>

                            <Row xs={1} md={2} className="g-4">
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <div style={{ width: '100%' }}>
                                                Evolução da receita
                                                <div>
                                                    <ResponsiveChartContainer
                                                        series={[
                                                            {
                                                                type: 'bar',
                                                                label: 'Receita',
                                                                color: 'lightgray',
                                                                data: this.state.graphicDataMR.revenue,
                                                                highlightScope: { highlight: 'item', fade: 'item' }
                                                            },
                                                            {
                                                                type: 'line',
                                                                color: '#ed4545',
                                                                label: 'Média (3 meses)',
                                                                data: this.state.graphicDataMR.revenueAvg,
                                                                highlightScope: { highlight: 'item', fade: 'item' }
                                                            }
                                                        ]}
                                                        height={400}
                                                        margin={{ top: 10 }}
                                                        xAxis={[
                                                            {
                                                                id: 'date',
                                                                data: this.state.graphicDataMR.months,
                                                                scaleType: 'band'
                                                            },
                                                        ]}
                                                        yAxis={[
                                                            {
                                                                id: 'price',
                                                                scaleType: 'linear',
                                                                valueFormatter: (value) => `${(value / 1000).toLocaleString()}k`
                                                            },
                                                            {
                                                                scaleType: 'linear',
                                                                valueFormatter: (value) => `${(value / 1000).toLocaleString()}k`
                                                            }
                                                        ]}
                                                    >
                                                        <ChartsAxisHighlight x="line" />
                                                        <BarPlot borderRadius={5} />
                                                        <LinePlot />

                                                        <LineHighlightPlot />
                                                        <ChartsXAxis
                                                            position="bottom"
                                                            axisId="date"
                                                            tickLabelStyle={{
                                                                fontSize: 10,
                                                            }}
                                                        />
                                                        <ChartsYAxis
                                                            position="left"
                                                            tickLabelStyle={{ fontSize: 10 }}
                                                        />
                                                        <ChartsTooltip />
                                                    </ResponsiveChartContainer>

                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <div style={{ width: '100%' }}>
                                                Histórico de clientes pagantes
                                                <div>
                                                    <BarChart
                                                        series={[
                                                            { data: this.graphicDataBreakdown_repeat, stack: 'A', label: 'Recorrentes', color: '#1f2125' },
                                                            { data: this.graphicDataBreakdown_nrepeat, stack: 'A', label: 'Não recorrentes', color: 'gray' },
                                                            { data: this.graphicDataBreakdown_new, stack: 'A', label: 'Novos', color: '#585e69' },
                                                            { data: this.graphicDataBreakdown_biwapp, stack: 'A', label: 'Recuperados', color: '#24d366' },
                                                            { data: this.graphicDataBreakdown_total, stack: 'B', label: 'Total', color: 'lightgray' }
                                                        ]}
                                                        borderRadius={3}
                                                        slotProps={{ legend: { hidden: true } }}
                                                        xAxis={[{
                                                            scaleType: 'band',
                                                            data: this.state.graphicDataMR.months,
                                                            tickLabelStyle: { fontSize: 10 }
                                                        }]}
                                                        yAxis={[{
                                                            tickLabelStyle: { fontSize: 10 }
                                                        }]}
                                                        // width={600}
                                                        height={400}
                                                    />

                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <div>
                                <br></br>
                            </div>

                            <Row xs={1} md={2} className="g-4">
                                <Col>
                                    <Card border="" className="text-center">
                                        <Card.Body>
                                            <div style={{ width: '100%' }}>
                                                Última visita de clientes
                                                <div>
                                                    <ResponsiveChartContainer
                                                        series={[
                                                            {
                                                                type: 'bar',
                                                                label: 'Última visita',
                                                                color: 'lightgray',
                                                                data: this.state.graphicDataMR.lastDates,
                                                                highlightScope: { highlight: 'item', fade: 'item' }
                                                            },
                                                            {
                                                                type: 'line',
                                                                color: '#ed4545',
                                                                label: 'Média (3 meses)',
                                                                data: this.state.graphicDataMR.lastDates,
                                                                highlightScope: { highlight: 'item', fade: 'item' }
                                                            }
                                                        ]}
                                                        height={400}
                                                        margin={{ top: 10 }}
                                                        xAxis={[
                                                            {
                                                                id: 'date',
                                                                data: this.state.graphicDataMR.months,
                                                                scaleType: 'band'
                                                            },
                                                        ]}
                                                        yAxis={[
                                                            {
                                                                id: 'price',
                                                                scaleType: 'linear',
                                                                valueFormatter: (value) => `${(value).toLocaleString()}`
                                                            },
                                                            {
                                                                scaleType: 'linear',
                                                                valueFormatter: (value) => `${(value).toLocaleString()}`
                                                            }
                                                        ]}
                                                    >
                                                        <ChartsAxisHighlight x="line" />
                                                        <BarPlot borderRadius={5} />
                                                        <LinePlot />

                                                        <LineHighlightPlot />
                                                        <ChartsXAxis
                                                            position="bottom"
                                                            axisId="date"
                                                            tickLabelStyle={{
                                                                fontSize: 10,
                                                            }}
                                                        />
                                                        <ChartsYAxis
                                                            position="left"
                                                            tickLabelStyle={{ fontSize: 10 }}
                                                        />
                                                        <ChartsTooltip />
                                                    </ResponsiveChartContainer>

                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>


                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Bi;