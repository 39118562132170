import { React, useRef } from 'react'
import * as XLSX from 'xlsx'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Button } from "react-bootstrap"

function FileInputBirthday() {

    const hiddenFileInput = useRef(null)

    const handleClick = event => {
        hiddenFileInput.current.click()
    }

    const handleChange = event => {
        handleFileUpload(event)
    }

    const userId = localStorage.getItem("resd")
    const campId = localStorage.getItem("camp")
    const user_pdv = localStorage.getItem("pdv")

    const daysToCutData = 2 * 365
    const daysToBeActive = 90
    const dateToSetActive = moment.utc(new Date()).format('YYYY-MM-01T00:00:00')

    const monthToImportBirthday = moment.utc(new Date()).format('MMMM')
    const monthToImportBirthday_number = moment.utc(new Date()).format('MM')
    const dateToImportBirthday = moment.utc(new Date()).format('YYYY-MM-15T00:00:00')

    const importDateDiaries = []
    const diaries = []

    const clientsAlive = []
    const campaigns = []

    let clientsGotten = false

    getClients()

    getImportDateDiaries()

    getDiaries()

    getCampaigns()

    const handleFileUpload = (e) => {

        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = async (event) => {
            const workbook = XLSX.read(event.target.result, { type: 'binary' })
            const sheetName = workbook.SheetNames[0]
            const sheet = workbook.Sheets[sheetName]
            const sheetData = XLSX.utils.sheet_to_json(sheet)

            const clientsFromSheet = await integrationSelector(sheetData)
            console.log(clientsFromSheet)

            if (clientsFromSheet.length > 0) {

                const clientsToUpdate = getClientsToUpdate(clientsFromSheet, clientsAlive)
                console.log(clientsToUpdate)
                if (clientsToUpdate.length > 0) {
                    await updateClient(clientsToUpdate)
                }

                const clientsToCreate = getClientsToCreate(clientsFromSheet, clientsAlive)
                console.log(clientsToCreate)
                if (clientsToCreate.length > 0) {
                    await createClient(clientsToCreate)
                }

                const totalClients = [...clientsToUpdate, ...clientsToCreate]

                await createImportDateDiary(totalClients, clientsFromSheet.length)

                await createDiary(clientsFromSheet)

                await updateCampaigns({ status_camp1: 0 }, campId)

                alert("Arquivo importado com sucesso!")
                window.location.reload()

            }

        }

        reader.readAsBinaryString(file);

    }

    async function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    async function integrationSelector(sheetData) {

        if (user_pdv === "allegro") {
            return await integration5asec(sheetData)
        }
        if (user_pdv === "elav") {
            return await integrationElav(sheetData)
        }
        if (user_pdv === "swl") {
            return await integrationSwl(sheetData)
        }

    }

    async function integration5asec(sheetData) {

        if (sheetData[0].__EMPTY_1 === 'Lista de Aniversariantes') {

            const fileMonth = (sheetData[0].__EMPTY_2).split(' ')

            if (fileMonth[fileMonth.length - 1].toLowerCase() === monthToImportBirthday) {

                while (!clientsGotten) {
                    // console.log("wait clients request")
                    await sleep(1000)
                }

                const clients = await setClientsBirthday_5asec(sheetData)

                const clearedClients = clients.filter(client => client.last_date !== ''
                    && client.last_date !== 'Invalid Date'
                    && (moment.utc(new Date(client.last_date)).diff(moment.utc(new Date(dateToSetActive)), 'days')) >= -daysToCutData)

                return clearedClients

            } else {
                alert('\nImportação do mês incorreto!'
                    + '\n\nVocê está importando o mês de ' + fileMonth[fileMonth.length - 1].toLowerCase()
                    + '\n\nPrecisamos do mês de ' + monthToImportBirthday)
            }
        } else {
            alert("\nO arquivo escolhido não é a lista de ANIVERSARIANTES DO MÊS compatível")
        }

        return []

    }

    async function integrationElav(sheetData) {

        console.log(sheetData)

        const planFieldNames = Object.getOwnPropertyNames(sheetData[0])

        let isBirthday = false
        let isExpectedMonth = false
        let fileMonthNumber = ''
        const monthExpectedToImport = monthToImportBirthday_number

        for (let index = 0; index < planFieldNames.length; index++) {
            const element = planFieldNames[index]
            if (element.includes("ANIVERSARIANTES")) {
                isBirthday = true

                const fieldItems = sheetData[3].__EMPTY_15.split('/')
                const fileEndDate = String(fieldItems[1])
                fileMonthNumber = fileEndDate

                if (fileMonthNumber === monthExpectedToImport) {
                    isExpectedMonth = true
                }
            }
        }

        console.log(isBirthday)
        console.log(isExpectedMonth)

        if (isBirthday) {

            if (isExpectedMonth) {

                while (!clientsGotten) {
                    // console.log("wait clients request")
                    await sleep(1000)
                }

                const clients = await setClientsBirthday_elav(sheetData)

                const clearedClients = clients.filter(client => (client.phone1 !== '' || client.phone2 !== '') && client.phone1 !== 'CELULAR')
                console.log(clearedClients)
                return clearedClients

            } else {
                alert('\nImportação do mês incorreto!'
                    + '\n\nVocê está importando o mês ' + fileMonthNumber
                    + '\n\nPrecisamos do mês ' + monthExpectedToImport)
            }

        } else {
            alert("\nO arquivo escolhido não é o RELATÓRIO DE PESSOAS ANIVERSARIANTES compatível")
        }

        return []

    }

    async function integrationSwl(sheetData) {

    }

    async function setClientsBirthday_5asec(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: (typeof client.__EMPTY_5 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)).toUTCString()
                    : typeof client.__EMPTY_5 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_5)).toUTCString()
                        : ''),
                birthday: (typeof client.__EMPTY != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY - 1)).toUTCString()
                    : typeof client.__EMPTY != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY)).toUTCString()
                        : ''),
                name: (typeof client.__EMPTY_1 != 'undefined' ? client.__EMPTY_1 : ''),
                phone1: (typeof client.__EMPTY_2 != 'undefined' ? client.__EMPTY_2 : ''),
                phone2: (typeof client.__EMPTY_3 != 'undefined' ? client.__EMPTY_3 : ''),
                first_date: (typeof client.__EMPTY_5 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_5 - 1)).toUTCString()
                    : typeof client.__EMPTY_5 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_5)).toUTCString()
                        : ''),
                tracking: {
                    last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                        : typeof client.__EMPTY_6 != 'undefined'
                            ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                            : ''),
                    ticket: 0,
                    visits: 0,
                    items: 0,
                    refDate: dateToImportBirthday
                },
                last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                last_last_date: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString()
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString()
                        : ''),
                ticket: 0,
                last_ticket: 0,
                last_last_ticket: 0,
                last_active_ticket: 0,
                camp_history: {
                    refDate: dateToImportBirthday,
                    camp1: false,
                    camp1_date: "",
                    camp2: false,
                    camp2_date: "",
                    camp3: false,
                    camp3_date: "",
                    camp4: false,
                    camp4_date: "",
                    camp5: false,
                    camp5_date: ""
                },
                camp1: '',
                camp2: '',
                camp3: '',
                camp4: '',
                camp5: '',
                active: (typeof client.__EMPTY_6 != 'undefined' && new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)) > new Date(Date.UTC(0, 0, 59, 0, 0, 0, 0))
                    ? (moment.utc(new Date(dateToSetActive)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6 - 1)).toUTCString())), 'days') < daysToBeActive ? true : false)
                    : typeof client.__EMPTY_6 != 'undefined'
                        ? (moment.utc(new Date(dateToSetActive)).diff(moment.utc(new Date(new Date(Date.UTC(0, 0, client.__EMPTY_6)).toUTCString())), 'days') < daysToBeActive ? true : false)
                        : false)
            })

        )
        return clients

    }

    async function setClientsBirthday_elav(data) {

        const clients = []

        data.map((client) =>

            clients.push({
                userId: userId,
                unique_identifier: 'not found',
                name: (typeof client.__EMPTY != 'undefined'
                    ? client.__EMPTY
                    : ''),
                phone1: (typeof client.__EMPTY_29 != 'undefined'
                    ? String(client.__EMPTY_29).replace(/[- ()]/g, '')
                    : ''),
                phone2: (typeof client.__EMPTY_25 != 'undefined'
                    ? String(client.__EMPTY_25).replace(/[- ()]/g, '')
                    : ''),
                birthday: (typeof client.__EMPTY_15 != 'undefined'
                    ? '2024-' + (String(client.__EMPTY_15).split('/'))[1] + '-' + (String(client.__EMPTY_15).split('/'))[1] + 'T03:00:00'
                    : '')
            })
        )
        return clients

    }

    function uniqueIdentifier(elem_first_date, allcli_first_date, elem_unique_identifier, allclie_unique_identifier) {
        if (user_pdv === 'allegro') {
            if (moment.utc(new Date(elem_first_date)).format('YYYY-MM-DD') === moment.utc(new Date(allcli_first_date)).format('YYYY-MM-DD')) {
                return true
            }
        }
        if (user_pdv === 'elav') {
            if (elem_unique_identifier === allclie_unique_identifier || elem_unique_identifier === 'not found') {
                return true
            }
        }
        return false
    }

    function getClientsToUpdate(data, allClients) {

        const clientsToUpdate = []
        console.log(allClients)

        data.filter(function (elem) {

            const cli = allClients.find(({ name, first_date, unique_identifier }) => (elem.name).toLowerCase() === name.toLowerCase()
                && uniqueIdentifier(elem.first_date, first_date, elem.unique_identifier, unique_identifier))

            if (typeof cli !== 'undefined') {
                clientsToUpdate.push({
                    name: cli.name,
                    unique_identifier: cli.unique_identifier,
                    phone1: cli.phone1,
                    phone2: cli.phone2,
                    birthday: elem.birthday,
                    active: cli.active,
                    _id: cli._id
                })
                return cli
            } else {
                return undefined
            }
        })

        if (clientsToUpdate.length >= 1) {
            return clientsToUpdate
        } else {
            return []
        }
    }

    function getClientsToCreate(data, allClients) {

        const clientsToCreate = data.filter((elem) => !allClients.find(({ name, first_date, unique_identifier }) => (elem.name).toLowerCase() === name.toLowerCase()
            && uniqueIdentifier(elem.first_date, first_date, elem.unique_identifier, unique_identifier)))

        if (clientsToCreate.length >= 1 && data[0].unique_identifier !== 'not found') {
            return clientsToCreate
        } else {
            return []
        }
    }

    async function updateClient(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function createClient(clientsVector) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(clientsVector)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    async function createDiary(data) {

        const diariesSorted = diaries.sort(function (a, b) {
            var c = new Date(a.ref_date);
            var d = new Date(b.ref_date);
            return d - c;
        })

        const monthToImport = getMonthToImportDiary(diariesSorted, data[0].birthday)

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const diaryVectorPATCH = {
                userId: userId,

                ref_date: monthToImport.ref_date,

                date_camp1: "",
                total_camp1_sent: 0,
                total_camp1_goal: 0,
                total_camp1_ticket: 0,

                date_camp2: "",
                total_camp2_sent: 0,
                total_camp2_goal: 0,
                total_camp2_ticket: 0,

                date_camp3: "",
                total_camp3_sent: 0,
                total_camp3_goal: 0,
                total_camp3_ticket: 0,

                date_camp4: "",
                total_camp4_sent: 0,
                total_camp4_goal: 0,
                total_camp4_ticket: 0
            }

            patchDiary(diaryVectorPATCH, monthToImport._id)

        } else {

            const diaryVectorPOST = {
                userId: userId,

                ref_date: new Date(dateToImportBirthday).toUTCString(),

                date_camp1: "",
                total_camp1_sent: 0,
                total_camp1_goal: 0,
                total_camp1_ticket: 0,

                date_camp2: "",
                total_camp2_sent: 0,
                total_camp2_goal: 0,
                total_camp2_ticket: 0,

                date_camp3: "",
                total_camp3_sent: 0,
                total_camp3_goal: 0,
                total_camp3_ticket: 0,

                date_camp4: "",
                total_camp4_sent: 0,
                total_camp4_goal: 0,
                total_camp4_ticket: 0
            }

            postDiary(diaryVectorPOST)

        }
    }

    async function createImportDateDiary(data, totalDBclients) {

        const importDateDiariesSorted = importDateDiaries.sort(function (a, b) {
            var c = new Date(a.date);
            var d = new Date(b.date);
            return d - c;
        })

        const monthToImport = getMonthToImport(importDateDiariesSorted, data[0].birthday)

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            const birthdayVectorPATCH = {

                userId: userId,

                date: monthToImport.date,

                birthday: {
                    total: totalDBclients,
                    actives: data.length,
                    importDate: new Date().toUTCString()
                },
                newClient: {
                    total: monthToImport.newClient.total,
                    actives: monthToImport.newClient.actives,
                    importDate: monthToImport.newClient.importDate
                },
                bestClients: {
                    total: monthToImport.bestClients.total,
                    actives: monthToImport.bestClients.actives,
                    importDate: monthToImport.bestClients.importDate
                },
                clientsDB: {
                    total: monthToImport.clientsDB.total,
                    actives: monthToImport.clientsDB.actives,
                    importDate: monthToImport.clientsDB.importDate
                }
            }

            patchBiDiary(birthdayVectorPATCH, monthToImport._id)

        } else {

            const birthdayVectorPOST = {

                userId: userId,

                date: new Date(dateToImportBirthday).toUTCString(),

                birthday: {
                    total: totalDBclients,
                    actives: data.length,
                    importDate: new Date().toUTCString()
                },

                newClient: {
                    total: 0,
                    actives: 0,
                    importDate: new Date().toUTCString()
                },

                bestClients: {
                    total: 0,
                    actives: 0,
                    importDate: new Date().toUTCString()
                },

                clientsDB: {
                    total: 0,
                    actives: 0,
                    importDate: new Date().toUTCString()
                }
            }

            postBiDiary(birthdayVectorPOST)

        }

    }

    function patchBiDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function patchDiary(data, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postBiDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function postDiary(data) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getImportDateDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/importDateDiary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    importDateDiaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getDiaries() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    diaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getClients() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                data.map((diary) => {
                    clientsAlive.push(diary)
                    return 0
                })
                clientsGotten = true
                // console.log("acabou o request")
                // console.log(data.length)
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function getCampaigns() {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                campaigns.push(data)
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    async function updateCampaigns(campaigns, id) {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    // console.log(res)
                } else {
                    console.log(res)
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    function getMonthToImport(data, birthdayDate) {

        console.log(birthdayDate)

        const birthdayMonthAndYear = moment.utc(new Date(birthdayDate)).format('MMMM') + moment.utc(new Date()).format('/YYYY')
        const birthdayMonthAndYear_alive = data.filter(bidiary => moment.utc(new Date(bidiary.date)).format('MMMM/YYYY') === birthdayMonthAndYear)

        if (birthdayMonthAndYear_alive.length >= 1) {
            return birthdayMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    function getMonthToImportDiary(data, birthdayDate) {

        const diaryMonthAndYear = moment.utc(new Date(birthdayDate)).format('MMMM') + moment.utc(new Date()).format('/YYYY')
        const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY') === diaryMonthAndYear)

        if (diaryMonthAndYear_alive.length >= 1) {
            return diaryMonthAndYear_alive[0]
        } else {
            return []
        }
    }

    return (
        <>

            <Button variant="dark" size="sm" onClick={handleClick} >
                Importar
            </Button>

            <input type="file" id="formId" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />

        </>
    )
}

export default FileInputBirthday;