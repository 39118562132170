import React from "react"
import { Form, Button, Card, Alert, CardGroup } from "react-bootstrap"
import moment from 'moment'
import 'moment/locale/pt-br'
import Message from "../Auxiliaries/Message"
import Loading from "../Auxiliaries/Loading"

// import { BsInfoCircle } from "react-icons/bs"
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'


class Messaging extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            campaigns: [],
            today: [],
            today_camp: [],

            img_camp2: '',
            msg_camp2: '',
            status_camp2: '',
            btn_camp2: true,
            alert_camp2: false,
            wapp_camp2: '',

            img_camp4: '',
            msg_camp4: '',
            status_camp4: '',
            btn_camp4: true,
            alert_camp4: false,
            wapp_camp4: '',

            loading_camp_data: "",

            clients: [],
            clients_active: [],
            clients_new: [],

            userId: localStorage.getItem("resd"),
            ownerName: '',
            ownerPhone: '',
            ownerPlace: '',

            wapp_status: '',

            fileUploadState2: '',
            fileUploadState4: ''
        }

        this.inputReference2 = React.createRef()
        this.inputReference4 = React.createRef()

        this.retry = 0
        this.daysToCutData = 2 * 365
        this.daysToBeActive = localStorage.getItem("act") //aviso geral

        this.dateToSetActive = moment.utc(new Date()).format('YYYY-MM-01T00:00:00')
        this.todayDate = moment(new Date()).format('YYYY-MM-15T00:00:00')
        this.campDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')
        this.diaries = []

        this.onPage = true

        this.wappMinuteRate = 4
        this.username = localStorage.getItem("username")
        this.plan = localStorage.getItem("nalp")

    }

    componentDidMount() {
        this.getUser(this.state.userId)
        this.getCampaigns(false)
        this.getClients()
        this.getDiaries()
        this.getWappStatus()
        this.checkSendingProgress()
    }

    componentWillUnmount() {
        this.onPage = false

    }

    getWappStatus = () => {

        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")

        fetch('https://waapi.app/api/v1/instances/' + instance_id + '/client/status', {
            method: 'GET',
            headers: {
                accept: 'application/json',
                authorization: 'Bearer ' + token
            }
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === "success") {
                    this.setState({
                        wapp_status: data.clientStatus.instanceStatus
                    })
                }
            })
            .catch(err => console.error('error:' + err))

    }

    checkSendingProgress = () => {

        if (this.onPage) {
            this.getCampaigns(true)

            setTimeout(() => {
                this.checkSendingProgress()
            }, 5000)
        } else {
            return
        }

    }

    getUser = (id) => {
        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/user/' + id, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    ownerName: data.name,
                    ownerPhone: data.phone,
                    ownerPlace: data.places[0].name + ' - ' + data.places[0].city_state
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getDiaries = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = this.state.userId

        fetch(BASE_URL + '/diary/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {

                this.setState({
                    today_camp: data
                        .map(diary => { return diary })
                        .filter(diary => moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY') === moment.utc(new Date()).subtract(1, 'month').format('MMMM/YYYY'))[0]
                })

                data.map((diary) => {
                    this.diaries.push(diary)
                    return 0
                })
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getClients = () => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = this.state.userId

        fetch(BASE_URL + '/client/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                this.setState({
                    clients: data,
                    clients_active: data.filter(client => moment.utc(new Date(this.dateToSetActive)).diff(moment.utc(new Date(client.last_date)), 'days') <= this.daysToBeActive
                        && client.hasWhatsapp
                    ),
                    clients_new: data.filter(client => moment.utc(new Date(client.first_date)).format('MMMM/YYYY') === moment(new Date(this.todayDate)).subtract(1, 'months').format('MMMM/YYYY')
                        && client.hasWhatsapp
                    )
                })
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    getCampaigns = (onlySendingProgress) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL
        const userId = this.state.userId

        fetch(BASE_URL + '/campaign/' + userId, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            })
        })
            .then(res => res.json())
            .then(data => {
                if (!onlySendingProgress) {
                    this.setState({
                        campaigns: data,
                        today: data,
                        msg_camp2: data.msg_camp2,
                        msg_camp4: data.msg_camp4,

                        img_camp2: data.img_camp2,
                        img_camp4: data.img_camp4,

                        status_camp2: data.status_camp2,
                        status_camp4: data.status_camp4,

                        loading_camp_data: data.userId
                    })
                } else {
                    this.setState({
                        status_camp2: data.status_camp2,
                        status_camp4: data.status_camp4
                    })
                }
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    deleteCampaigns = (id) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, { method: "DELETE" })
            .then(res => {
                if (res.ok) {
                    this.getCampaigns()
                }
            })
            .catch(rejected => {
                console.log(rejected)
            });
    }

    createCampaigns = (campaigns) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    this.getCampaigns()
                } else {
                    alert('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    updateCampaigns = (campaigns, id, camp) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/campaign/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(campaigns)
        })
            .then(res => {
                if (res.ok) {
                    this.getCampaigns()

                    if (camp === 'camp2') {
                        this.setState({ alert_camp2: true })
                    }
                    if (camp === 'camp4') {
                        this.setState({ alert_camp4: true })
                    }

                } else {
                    alert('Não foi possível atualizar a campanha!')
                    console.log(res)
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    updateMessage2 = (e) => {
        this.setState({
            msg_camp2: e.target.value
        })
    }
    updateMessage4 = (e) => {
        this.setState({
            msg_camp4: e.target.value
        })
    }

    deleteImg = (camp) => {
        if (camp === 'camp2') {
            this.setState({ img_camp2: '' })
        }
        if (camp === 'camp4') {
            this.setState({ img_camp4: '' })
        }
    }

    submit = (id, camp) => {

        this.setState({
            alert_camp2: false,
            alert_camp4: false
        })

        if (camp === 'camp2') {
            this.updateCampaigns({
                img_camp2: this.state.img_camp2,
                msg_camp2: this.state.msg_camp2
            }, id, camp)
        }
        if (camp === 'camp4') {
            this.updateCampaigns({
                img_camp4: this.state.img_camp4,
                msg_camp4: this.state.msg_camp4
            }, id, camp)
        }
    }

    submitWapp = async (camp) => {

        if (this.state.wapp_status === 'ready') {
            this.setState({
                wapp_camp2: '',
                wapp_camp4: ''
            })

            if (camp === 'camp2') {
                this.sendWhatsappCampaign(this.state.clients_new, camp)
            }
            if (camp === 'camp4') {
                this.sendWhatsappCampaign(this.state.clients_active, camp)
            }
        } else {
            //wapp not connected
            if (camp === 'camp2') {
                this.setState({ wapp_camp2: 'error' })
            }
            if (camp === 'camp4') {
                this.setState({ wapp_camp4: 'error' })
            }
            await this.sleep(8000)
            window.location.href = "/configs"
        }



    }

    sendWhatsappCampaign = async (targetClients, camp) => {

        const msgToSend = []

        var image = ''
        var beginningOwnerMsg = ''
        var endingOwnerMsg = ''

        if (camp === 'camp2') {
            image = this.state.img_camp2
            beginningOwnerMsg = 'Envio da campanha: *Cliente novo*\n\nTotal de ' + targetClients.length
                + ' clientes a serem enviados\n\nPrevisão de ' + (Math.abs(targetClients.length / this.wappMinuteRate) + 1).toFixed(0) + ' minutos\n\n'
                + 'Ao final do processo, você receberá uma mensagem de conclusão dos envios.'
            endingOwnerMsg = 'Envio da campanha *Cliente novo* concluído com sucesso!'
        }
        if (camp === 'camp4') {
            image = this.state.img_camp4
            beginningOwnerMsg = 'Envio da campanha: *Aviso geral*\n\nTotal de ' + targetClients.length
                + ' clientes a serem enviados\n\nPrevisão de ' + (Math.abs(targetClients.length / this.wappMinuteRate) + 1).toFixed(0) + ' minutos\n\n'
                + 'Ao final do processo, você receberá uma mensagem de conclusão dos envios.'
            endingOwnerMsg = 'Envio da campanha *Aviso geral* concluído com sucesso!'
        }

        targetClients.map((client) => {
            const name = client.name.split(" ")[0].charAt(0).toUpperCase() + client.name.split(" ")[0].slice(1).toLowerCase()

            const phoneNumber = client.phone1

            var message = ''

            if (camp === 'camp2') {
                message = this.state.msg_camp2.replace('(NOME)', name)
            }
            if (camp === 'camp4') {
                message = this.state.msg_camp4.replace('(NOME)', name)
            }

            msgToSend.push({
                message: message,
                phoneNumber: phoneNumber
            })

            return 0
        })

        const dontRemoveNine = (Number(localStorage.getItem("ddd")) >= 11 && Number(localStorage.getItem("ddd")) <= 28) //sp 11-19, rj 21,22,24, es 27-28
        let ownerPhoneFinal = (this.state.ownerPhone).replace(/[-+ ()_]/g, '')
        if (!dontRemoveNine) {
            console.log('DDD para RETIRAR 9: ddd ' + localStorage.getItem("ddd"))
            if (ownerPhoneFinal.length === 13) {
                ownerPhoneFinal = ownerPhoneFinal.slice(0, 4) + ownerPhoneFinal.slice(5, 13)
            }
        }

        const ownerPlace = this.state.ownerPlace
        const instance_id = localStorage.getItem("cenaw")
        const token = localStorage.getItem("nekw")


        //control BIWAPP received msg - START
        msgToSend.unshift({
            message: beginningOwnerMsg,
            phoneNumber: '5511939501767'
        })
        //adding owner awareness msg at beginning of sending wapp msgs
        msgToSend.unshift({
            message: beginningOwnerMsg,
            phoneNumber: ownerPhoneFinal,
            place: ownerPlace,

            instance_id: instance_id,
            token: token,
            campaign_id: this.state.campaigns._id,
            campaign: camp,
            image: image
        })
        //control BIWAPP received msg - END
        msgToSend.push({
            message: endingOwnerMsg,
            phoneNumber: '5511939501767'
        })
        //adding owner awareness msg at the end of sending wapp msgs
        msgToSend.push({
            message: endingOwnerMsg,
            phoneNumber: ownerPhoneFinal
        })

        this.retry = 0

        if (process.env.REACT_APP_MODE === "dev") {
            console.log("Dev MODE - NOT sending real WhatsApp")
        } else {
            console.log("Prod MODE - SENDING real WhatsApp")
            this.sendWappMessages(msgToSend)
        }

        this.updateDiary(camp)

        if (camp === 'camp2') {
            this.setState({
                wapp_camp2: 'sent',
                btn_camp2: false
            })
        }
        if (camp === 'camp4') {
            this.setState({
                wapp_camp4: 'sent',
                btn_camp4: false
            })
        }

    }

    updateDiary = (camp) => {

        const diariesSorted = this.diaries.sort(function (a, b) {
            var c = new Date(a.date_camp1);
            var d = new Date(b.date_camp1);
            return d - c;
        })

        const monthToImport = this.getMonthToImportDiary(diariesSorted, camp)

        if (monthToImport.length !== 0 && monthToImport._id !== 0) {

            if (camp === 'camp2') {
                const diaryVectorPATCH = {
                    date_camp2: this.campDate,
                    total_camp2_sent: this.state.clients_new.length,
                    total_camp2_goal: 0
                }
                this.patchDiary(diaryVectorPATCH, monthToImport._id)

                const clientsToUpdateCamp2 = this.clientsToUpdate(this.state.clients_new, camp)
                this.updateClient(clientsToUpdateCamp2)
            }
            if (camp === 'camp4') {
                const diaryVectorPATCH = {
                    date_camp4: this.campDate,
                    total_camp4_sent: this.state.clients_active.length,
                    total_camp4_goal: 0
                }
                this.patchDiary(diaryVectorPATCH, monthToImport._id)

                const clientsToUpdateCamp4 = this.clientsToUpdate(this.state.clients_active, camp)
                this.updateClient(clientsToUpdateCamp4)
            }
        } else {
            // deu pau - nao criou 1 diary quando importou o mês correto, checar!
        }
    }

    clientsToUpdate = (data, camp) => {

        const clientsToUpdate = []

        data.map((client) => {

            const objIndex = client.camp_history.findIndex(obj => moment(new Date(obj.refDate)).format('MMMM/YYYY') === moment(new Date(this.todayDate)).format('MMMM/YYYY'))

            if (objIndex < 0) {
                //create obj
                const camp_history = {
                    refDate: moment.utc(new Date(this.todayDate)).format('YYYY-MM-15T00:00:00'),
                    camp2: (camp === 'camp2' ? true : false),
                    camp2_date: (camp === 'camp2' ? this.campDate : ""),
                    camp4: (camp === 'camp4' ? true : false),
                    camp4_date: (camp === 'camp4' ? this.campDate : "")
                }
                //push into already existing one
                client.camp_history.push(camp_history)
            } else {
                //update obj
                if (camp === 'camp2') {
                    client.camp_history[objIndex].camp2 = true
                    client.camp_history[objIndex].camp2_date = this.campDate
                }
                if (camp === 'camp4') {
                    client.camp_history[objIndex].camp4 = true
                    client.camp_history[objIndex].camp4_date = this.campDate
                }
            }

            if (camp === 'camp2') {
                clientsToUpdate.push({
                    camp2: moment.utc(new Date(this.todayDate)).format('MMMM/YYYY'),
                    _id: client._id,
                    camp_history: client.camp_history
                })
            }
            if (camp === 'camp4') {
                clientsToUpdate.push({
                    camp4: moment.utc(new Date(this.todayDate)).format('MMMM/YYYY'),
                    _id: client._id,
                    camp_history: client.camp_history
                })
            }
            return 0
        })
        return clientsToUpdate
    }

    updateClient = (data) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/clientsArray', {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })

    }

    patchDiary = (data, id) => {

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/diary/' + id, {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(data)
        })
            .then(res => {
                if (res.ok) {
                    // alert('Arquivo importado com sucesso!')
                    return res
                } else {
                    // console.log('Não foi possível adicionar a campanha!')
                }
            })
            .catch(rejected => {
                console.log(rejected)
            })
    }

    getMonthToImportDiary = (data, camp) => {

        if (camp === 'camp2' || camp === 'camp3') {
            // const diaryMonthAndYear = moment(new Date()).subtract(1, 'months').format('MMMM/YYYY')
            const diaryMonthAndYear = moment(new Date(this.todayDate)).format('MMMM/YYYY')
            const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY') === diaryMonthAndYear)

            if (diaryMonthAndYear_alive.length >= 1) {
                return diaryMonthAndYear_alive[0]
            } else {
                return []
            }
        } else {
            const diaryMonthAndYear = moment(new Date(this.todayDate)).format('MMMM/YYYY')
            const diaryMonthAndYear_alive = data.filter(diary => moment.utc(new Date(diary.ref_date)).format('MMMM/YYYY') === diaryMonthAndYear)

            if (diaryMonthAndYear_alive.length >= 1) {
                return diaryMonthAndYear_alive[0]
            } else {
                return []
            }
        }
    }

    sendWappMessages = (wappMessages) => {

        console.log("Tentativa " + (this.retry + 1))

        const BASE_URL = process.env.REACT_APP_BASE_URL

        fetch(BASE_URL + '/wappMsg', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("nekb")
            },
            body: JSON.stringify(wappMessages)
        })
            .then(async res => {
                if (res.ok) {
                    return res
                } else {
                    if (this.retry <= 2) {
                        console.log('Não foi possível, tentando novamente!')
                        await this.sleep(5000)
                        this.sendWappMessages(wappMessages)

                        this.retry = this.retry + 1
                    } else {
                        console.log('Não foi possível, tentamos 3x')
                    }
                }
            })
            .catch(rejected => {
                // console.log('Catch - rejected')
                console.log(rejected)
            })
    }

    sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        })
    }

    fileUploadAction2 = () => this.inputReference2.current.click();

    fileUploadInputChange2 = (e) => {
        this.setState({ fileUploadState2: e.target.value })
        this.getBase64(e, 'camp2')
    }

    fileUploadAction4 = () => this.inputReference4.current.click();

    fileUploadInputChange4 = (e) => {
        this.setState({ fileUploadState4: e.target.value })
        this.getBase64(e, 'camp4')
    }

    getBase64 = (e, camp) => {

        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            if (camp === 'camp2') {
                this.setState({ img_camp2: reader.result })
            }
            if (camp === 'camp4') {
                this.setState({ img_camp4: reader.result })
            }
        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
        }
    }

    render() {
        return (
            <>
                <div style={{ padding: '80px 20px' }} >
                    {this.state.loading_camp_data !== '' && this.state.wapp_status !== ''
                        ?
                        <>
                            <h3>Mensageria</h3>

                            {this.state.alert_camp2
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={2} variant='primary' className="text-center">
                                            A campanha de <b>cliente novo</b> foi salva com sucesso!
                                        </Alert>
                                    </div>
                                </Message>
                                : ''}
                            {this.state.wapp_camp2 === 'sent'
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={2} variant='success' className="text-center">
                                            <Alert.Heading>Enviando campanha pelo WhatsApp</Alert.Heading>
                                            <br></br>
                                            A campanha de <b>cliente novo</b> está sendo envida com sucesso!
                                            <hr />
                                            Tempo estimado: <b>{Math.abs((this.state.clients_new.length / this.wappMinuteRate) + 1).toFixed(0)} minuto(s)</b>
                                        </Alert>
                                    </div>
                                </Message>
                                : this.state.wapp_camp2 === 'error'
                                    ? <Message>
                                        <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                            <Alert key={4} variant='danger' className="text-center">
                                                <Alert.Heading>WhatsApp não conectado</Alert.Heading>
                                                <br></br>
                                                Conecte seu número de WhatsApp na plataforma antes de usar o envio de campanhas.
                                                <hr />
                                                Você será redirecionado para a página de configurações
                                            </Alert>
                                        </div>
                                    </Message>
                                    : ''}

                            {this.state.alert_camp4
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={4} variant='primary' className="text-center">
                                            A campanha de <b>aviso geral</b> foi salva com sucesso!
                                        </Alert>
                                    </div>
                                </Message>
                                : ''}
                            {this.state.wapp_camp4 === 'sent'
                                ? <Message>
                                    <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                        <Alert key={4} variant='success' className="text-center">
                                            <Alert.Heading>Enviando campanha pelo WhatsApp</Alert.Heading>
                                            <br></br>
                                            A campanha <b>aviso geral</b> está sendo envida com sucesso!
                                            <hr />
                                            Tempo estimado: <b>{Math.abs((this.state.clients_active.length / this.wappMinuteRate) + 1).toFixed(0)} minuto(s)</b>
                                        </Alert>
                                    </div>
                                </Message>
                                : this.state.wapp_camp4 === 'error'
                                    ? <Message>
                                        <div style={{ width: 600, height: 50, margin: 'auto', position: "absolute", top: 60, left: 0, right: 0, zIndex: 999 }}>
                                            <Alert key={4} variant='danger' className="text-center">
                                                <Alert.Heading>WhatsApp não conectado</Alert.Heading>
                                                <br></br>
                                                Conecte seu número de WhatsApp na plataforma antes de usar o envio de campanhas.
                                                <hr />
                                                Você será redirecionado para a página de configurações
                                            </Alert>
                                        </div>
                                    </Message>
                                    : ''}

                            <CardGroup>

                                <Card border="" className="text-center">
                                    <Card.Header><b>Cliente novo</b></Card.Header>
                                    {this.state.img_camp2 !== ''
                                        ? <Card.Img variant="top" src={this.state.img_camp2}
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />
                                        : <Card.Img variant="top" src='./images/sem_foto.png'
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />}
                                    <Card.Body>
                                        <div>
                                            <input type="file" hidden ref={this.inputReference2} onChange={this.fileUploadInputChange2} />
                                            <Button variant="dark" size="sm" onClick={this.fileUploadAction2}>
                                                {this.state.img_camp2 !== '' ? 'Alterar' : 'Adicionar foto'}
                                            </Button> {' '}
                                            {this.state.img_camp2 !== ''
                                                ? <Button variant="dark" size="sm" onClick={() => this.deleteImg('camp2')}>
                                                    Excluir foto</Button>
                                                : ''}
                                        </div>
                                        <br></br>
                                        <Form>
                                            <Form.Control as="textarea" rows={8} value={this.state.msg_camp2}
                                                placeholder="Insira uma mensagem" onChange={this.updateMessage2} />
                                        </Form>
                                        <br></br>
                                        Total de novos clientes: <b>{this.state.clients_new.length}</b>
                                        <br></br>
                                        <span className="CardFooter" style={{ color: 'gray' }}>Ref. {moment(new Date()).subtract(1, 'months').format('MMMM/YYYY')}</span>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Button variant="dark" size="sm" onClick={() => this.submit(this.state.campaigns._id, 'camp2')}>
                                            Salvar
                                        </Button>
                                        {' '}
                                        {this.username === "virginia"
                                            ? this.state.status_camp2 === 0 && this.state.btn_camp2 && this.state.clients_new.length !== 0
                                                ? <Button className="buttonBIWAPP" size="sm" onClick={() => this.submitWapp('camp2')}>
                                                    Enviar WhatsApp
                                                </Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(this.state.status_camp2).toFixed(1)}% enviado</Button>
                                            : this.state.status_camp2 === 0
                                                ? <Button className="buttonBIWAPP" size="sm" disabled>Agendado</Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(this.state.status_camp2).toFixed(1)}% enviado</Button>}
                                    </Card.Footer>
                                </Card>

                                <Card border="" className="text-center">
                                    <Card.Header style={{ text: '10px' }}><b>Aviso geral</b></Card.Header>
                                    {this.state.img_camp4 !== ''
                                        ? <Card.Img variant="top" src={this.state.img_camp4}
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />
                                        : <Card.Img variant="top" src='./images/sem_foto.png'
                                            style={{
                                                height: '200px',
                                                width: 'auto',
                                                alignSelf: 'center'
                                            }} />}
                                    <Card.Body>
                                        <div>
                                            <input type="file" hidden ref={this.inputReference4} onChange={this.fileUploadInputChange4} />
                                            {this.plan !== "300"
                                                ? <><Button variant="dark" size="sm" onClick={this.fileUploadAction4}>
                                                    {this.state.img_camp4 !== '' ? 'Alterar' : 'Adicionar foto'}
                                                </Button> {' '}
                                                    {this.state.img_camp4 !== ''
                                                        ? <Button variant="dark" size="sm" onClick={() => this.deleteImg('camp4')}>
                                                            Excluir foto</Button>
                                                        : ''}</>
                                                : <><Button variant="dark" size="sm" onClick={this.fileUploadAction4} disabled>
                                                    {this.state.img_camp4 !== '' ? 'Alterar' : 'Adicionar foto'}
                                                </Button> {' '}
                                                    {this.state.img_camp4 !== ''
                                                        ? <Button variant="dark" size="sm" onClick={() => this.deleteImg('camp4')} disabled>
                                                            Excluir foto</Button>
                                                        : ''}</>
                                            }

                                        </div>
                                        <br></br>
                                        <Form>
                                            <Form.Control as="textarea" rows={8} value={this.state.msg_camp4}
                                                placeholder="Insira uma mensagem" onChange={this.updateMessage4} />
                                        </Form>
                                        <br></br>
                                        Total de clientes sugerido: <b>{this.state.clients_active.length}</b>
                                        <br></br>
                                        <span className="CardFooter" style={{ color: 'gray' }}>Última visita a menos de {this.daysToBeActive} dias</span>
                                    </Card.Body>
                                    <Card.Footer>
                                        {this.plan !== "300"
                                            ? <Button variant="dark" size="sm" onClick={() => this.submit(this.state.campaigns._id, 'camp4')}>
                                                Salvar
                                            </Button>
                                            : <Button disabled variant="dark" size="sm" onClick={() => this.submit(this.state.campaigns._id, 'camp4')}>
                                                Salvar
                                            </Button>}
                                        {' '}
                                        {this.plan !== "300"
                                            ?
                                            (this.state.status_camp4 === 0 || this.state.status_camp4 > 99.9) && this.state.btn_camp4 && this.username === "virginia"
                                                ? <Button className="buttonBIWAPP" size="sm" onClick={() => this.submitWapp('camp4')}>
                                                    Enviar WhatsApp
                                                </Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled >{(this.state.status_camp4).toFixed(1)}% enviado</Button>
                                            :
                                            (this.state.status_camp4 === 0 || this.state.status_camp4 > 99.9) && this.state.btn_camp4
                                                ? <Button className="buttonBIWAPP" size="sm" disabled onClick={() => this.submitWapp('camp4')}>
                                                    Enviar WhatsApp
                                                </Button>
                                                : <Button className="buttonBIWAPP" size="sm" disabled>{(this.state.status_camp4).toFixed(1)}% enviado</Button>
                                        }
                                    </Card.Footer>
                                </Card>

                                <Card border="" className="text-center">
                                    <Card.Header><b>Taxa de retorno</b></Card.Header>

                                    <Card.Body>

                                        <Card border="" className="text-center">
                                            <Card.Body>
                                                <Card.Title>Cliente novo</Card.Title>
                                                <br></br>
                                                <div style={{ width: 70, height: 70, margin: '0 auto' }}>
                                                    <CircularProgressbarWithChildren value={this.state.today_camp.total_camp2_sent > 0 ? (Math.abs(((this.state.today_camp.total_camp2_goal) / (this.state.today_camp.total_camp2_sent) * 100).toFixed(0))) : 0}
                                                        styles={{
                                                            path: {
                                                                // Path color
                                                                stroke: '#24d366',
                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                strokeLinecap: 'round'
                                                            },
                                                            text: {
                                                                // Text color
                                                                fill: '#000',
                                                                // Text size
                                                                fontSize: '40px'
                                                            }
                                                        }}>
                                                        <div style={{ fontSize: 18, marginTop: -5 }}>
                                                            <strong>{( this.state.today_camp.total_camp2_sent > 0 ? (Math.abs(((this.state.today_camp.total_camp2_goal) / (this.state.today_camp.total_camp2_sent) * 100).toFixed(0))) : 0 ) + '%'}</strong>
                                                        </div>
                                                    </CircularProgressbarWithChildren>
                                                </div>
                                                <Card.Text>
                                                    <br></br>
                                                    {/* {this.state.today.total_camp2_sent > 0 ? <><a style={{ color: 'gray' }} href={'/clientsnew/' + moment.utc(new Date(this.state.today.date_camp2)).format('YYYY-MM-15')}><BsInfoCircle /></a>{' '}</> : ""}<b>{this.state.today.total_camp2_goal}</b> de {this.state.today.total_camp2_sent} envios */}
                                                    <b>{this.state.today_camp.total_camp2_goal > 0 ? this.state.today_camp.total_camp2_goal : 0}</b> de {this.state.today_camp.total_camp2_sent > 0 ? this.state.today_camp.total_camp2_sent : 0} envios
                                                    <br></br>
                                                    <br></br>
                                                    <b>R$ {(this.state.today_camp.total_camp2_ticket).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</b>
                                                    <br></br>
                                                    <span className="CardFooter" style={{ color: 'gray' }}>Cliente novo reincidente | ref. {moment.utc(new Date()).subtract(1, 'month').format('MMM/YYYY')}</span>
                                                    <br></br>
                                                    <span className="CardFooter" style={{ color: 'gray' }}><b>Gasto médio total | R$ 0,00</b></span>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>

                                        <br></br>

                                        {/* <Card border="" className="text-center">
                                            <Card.Body>
                                                <Card.Title>Aviso geral</Card.Title>
                                                <br></br>
                                                <div style={{ width: 70, height: 70, margin: '0 auto' }}>
                                                    <CircularProgressbarWithChildren value={0}
                                                        styles={{
                                                            path: {
                                                                // Path color
                                                                stroke: '#24d366',
                                                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                                                strokeLinecap: 'round'
                                                            },
                                                            text: {
                                                                // Text color
                                                                fill: '#000',
                                                                // Text size
                                                                fontSize: '40px'
                                                            }
                                                        }}>
                                                        <div style={{ fontSize: 18, marginTop: -5 }}>
                                                            <strong>{0 + '%'}</strong>
                                                        </div>
                                                    </CircularProgressbarWithChildren>
                                                </div>
                                                <Card.Text>
                                                    <br></br>
                                                    <b>0</b> de 0 envios
                                                    <br></br>
                                                    <br></br>
                                                    <span className="CardFooter" style={{ color: 'gray' }}>Mensagens enviadas | ref. {moment.utc(new Date()).subtract(1, 'month').format('MMM/YYYY')}</span>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card> */}

                                    </Card.Body>
                                </Card>

                            </CardGroup>
                        </>
                        :
                        <Loading />}
                </div>
            </>
        )
    }

}

export default Messaging